import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const CustomDialog = ({ open, handleClose , handleYesClick }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Submission</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to submit the data?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" style={{
          backgroundColor: 'red', 
          color: 'white',
          border: '1px solid red',
          borderRadius: '10px',
        }}>
          Cancel
        </Button>
        <Button onClick={handleYesClick} color="primary" style={{
          backgroundColor: 'green', 
          color: 'white',
          border: '1px solid green',
          borderRadius: '10px',
          
        }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
