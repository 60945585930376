import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LetterTemplateTab from "./LetterTemplateTab";
import NewLetterTemplate from "./NewLetterTemplate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "80px",
  },
}));

const LetterTemplateMain = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isNewLetter, setIsNewLetter] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Main" {...a11yProps(0)} />
        {/* <Tab label="Activity" {...a11yProps(1)} /> */}
      </Tabs>
      {/* </AppBar> */}

      <div className="px-14 py-5">
        <TabPanel value={value} index={0}>
          <p className="bg-[#f2f9fb] p-4">
            The <span className="font-bold">Letter Template </span>page helps
            you create various letter templates according to your companys
            requirements. In the{" "}
            <span className="font-bold">Template Gallery</span>tab we have ready
            templates that you can use.Select the one that best suits your
            requirement. You can edit the ready-to-use templates to match your
            needs using the content.
          </p>
          {isNewLetter ? (
            <NewLetterTemplate setIsNewLetter={setIsNewLetter} />
          ) : (
            <div className="mt-10">
              <LetterTemplateTab setIsNewLetter={setIsNewLetter} />
            </div>
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          Activity
        </TabPanel> */}
      </div>
    </div>
  );
};

export default LetterTemplateMain;
