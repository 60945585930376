import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Snackbar, Alert } from "@mui/material";
import eyeClose from "../Icons/eyeclose.svg";
import eyeOpen from "../Icons/eyeopen.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updatePasswordSnackbar } from "../store/reducers/addEmployeeSlice";
import { updateEmployeeId } from "../store/reducers/addEmployeeSlice";
import Loader from "../Components/customComponents/customLoader";

function RegistrationScreen() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setverificationCode] = useState("");
  const [payload, setPayload] = useState({});
  const [showPassword, setShowPassword] = useState();
  const [confirmShowPassword, setConfirmShowPassword] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    error: false,
    message: "",
  });
  const employeeLoginId = useSelector(
    (state) => state.addEmployee.employeeloginId
  );
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordInputRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get("code");
    const employeeId = codeParam.split("::");
    dispatch(updateEmployeeId(employeeId[0]));
    setverificationCode(codeParam);
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen({
      open: false,
      error: false,
      message: "",
    });
  };

  const handleSuccessSnackOpen = (open, error, message) => {
    let snackData = { ...snackbarOpen };
    snackData.open = open;
    snackData.error = error;
    snackData.message = message;
    setSnackbarOpen(snackData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (verificationCode && password) {
      // Create the payload with code and password
      const payload = { verificationCode, password };
      setPayload(payload);
      setLoading(true);

      try {
        let regex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;

        if (regex.test(password)) {
          const response = await axios.post(
            `${serviceUrl}/api/auth/verfiyUser`,
            payload
          );
          if (response.status === 201) {
            setLoading(false);
            handleSuccessSnackOpen(
              true,
              false,
              "Password Created Successfully"
            );
            setTimeout(() => {
              navigate("/login");
            }, [1000]);
          }

        } else { 
          setPasswordError(
            `
            * Password must contain at least one lowercase letter,
            * one uppercase letter,
            * one digit,
            * one special character (@.#$!%*?&^).
            * It should be 8 to 15 characters long.
            `
          );

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        handleSuccessSnackOpen(true, true, "Verification Link Expired"); // Open the Snackbar on error
        
        console.error("API Request Error:", error);
      }
    } else {
      console.error("Code and/or password are missing.");
    }
  };
  const handleConfirmPasswordKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="mt-[18.5rem] flex flex-col items-center">
      <div className="text-2xl font-bold text-black mb-4">
  Welcome, {employeeLoginId}
</div>
        <h1 className="text-2xl text-gray-500 font-normal mb-4 font-serif">Create Your Secure Password</h1>

        <div className="mb-4">
          <label
            className="block text-blue-700 text-lg font-bold mb-2 "
            htmlFor="password"
          >
            New Password :
          </label>
          <div className="flex items-center">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
            />
            <img
              className="-ml-[23px]"
              src={showPassword ? eyeOpen : eyeClose}
              onClick={() => setShowPassword(!showPassword)}
            ></img>
          </div>

          <label
            className="block text-blue-700 text-lg font-bold mb-2"
            htmlFor="password"
          >
            Confirm Password :
          </label>
          <div className="flex items-center">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={confirmShowPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onKeyDown={handleConfirmPasswordKeyDown}
              ref={passwordInputRef}
              tabIndex="0"
            />
            <img
              className="-ml-[23px]"
              src={confirmShowPassword ? eyeOpen : eyeClose}
              onClick={() => {
                setConfirmShowPassword(!confirmShowPassword);
                passwordInputRef.current.focus();
              }}
            ></img>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className={`${
              password !== confirmPassword ||
              password == "" ||
              confirmPassword == ""
                ? "bg-gray-500 hover:bg-gray-700 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            } cursor-pointer`}
            disabled={
              password !== confirmPassword ||
              password == "" ||
              confirmPassword == ""
            }
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={snackbarOpen.open}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            severity={snackbarOpen.error ? "error" : "success"}
            variant="filled"
            onClose={handleSnackbarClose}
          >
            {snackbarOpen.message}
          </Alert>
        </Snackbar>
        {password !== confirmPassword &&
          password !== "" &&
          confirmPassword !== "" && (
            <div className="text-red-500 mt-2">
              Passwords do not match. Please try again.
            </div>
          )}
        {passwordError && (
          <div>
            {passwordError && (
              <div>
                {passwordError.split("\n").map((line, index) => (
                  <p
                    key={index}
                    className={
                      (index === 1 && /[a-z]/.test(password)) ||
                      (index === 2 && /[A-Z]/.test(password)) ||
                      (index === 3 && /\d/.test(password)) ||
                      (index === 4 && /[@.#$!%*?&^]/.test(password)) ||
                      (index === 5 &&
                        password.length >= 8 &&
                        password.length <= 15)
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    {line}
                  </p>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default RegistrationScreen;
