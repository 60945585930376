import React from "react";
import Marquee from "react-fast-marquee";

const Topbar = () => {
  return (
    <div className=" px-2 md:px-6 py-2 w-full bg-[#e9d0ff] flex justify-between items-center text-black">
      <div className="lg:w-[80%] w-[90%] flex  overflow-hidden m-auto">
        <Marquee
          speed={70}
          delay={3}
          pauseOnHover
          gradient
          gradientColor="#e9d0ff"
          gradientWidth={20}
        >
          <p className="text-[#3d006f]  inline  ml-5">
            <span className="text-base	 md:text-lg font-bold mr-5">
              South India's Largest and Highest Rated GLOBAL EDUCATION FAIR-2024
            </span>
          </p>
          <div className="flex text-[#3d006f]">
            <div className="flex items-center mr-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                />
              </svg>
              <p className="ml-3">3rd August 2024</p>
            </div>
            <div className="flex items-center mr-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p className="ml-3">10AM to 5PM</p>
            </div>
            <div className="flex items-center mr-20">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>
              <p className="ml-3">
                TIRUPATI ,Kences Hotel ,Opp.APSRTC Bus Stand
              </p>
            </div>
          </div>
        </Marquee>
      </div>

      <button
        className="block w-[80px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full md:w-[120px] text-[10px] md:text-base"
        onClick={() => {
          window.open("https://register-icampusconnect.web.app/");
        }}
      >
        Know more
      </button>
    </div>
  );
};

export default Topbar;