import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useGetAllTenantsQuery, useGetUpdateMutation } from "../../api/tenantApi";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "../customComponents/customTextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ManageTenants = () => {
  const [tenantData, setTenantData] = useState([]);
const [getUpdate,] = useGetUpdateMutation();
const {data, isError, refetch:isRefetchTenants} = useGetAllTenantsQuery();
const [openDialog, setOpenDialog] = useState(false);
const [selectedRowData, setSelectedRowData] = useState(null);

useEffect(()=>{
  isRefetchTenants();
},[])

useEffect(() => {
  if (data) {
    const dataWithId = data.map((row, index) => ({ ...row, id: index + 1 }));
    setTenantData(dataWithId);
  }
}, [data]);

useEffect(() => {
 if(isError){
  setTenantData([]);
 }
},[isError])
const handleEdit = (rowData) => {
  setSelectedRowData(rowData);
  setOpenDialog(true);
};
const handleUrlChange = (e)=>{
  const value = e.target.value;
  setSelectedRowData(prevState => ({...prevState, logoUrl: value}));
}
let mongoId ;
data?.forEach((item)=>{
  if(item?.tenantName === selectedRowData?.tenantName){
    mongoId = item._id
}});
  const columns = [
    { field: "createdDate", headerName: "Created Date", width: 150 },
    { field: "tenantName", headerName: "Tenant Name", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNumber", headerName: "Phone Number", type: "number", width: 150 }, 
    { field: "logoUrl", headerName: "logoUrl", width: 90 },
    { field: "address", headerName: "address", width: 90 },
    { field: "edit", headerName: "Edit", width: 90, renderCell: (params) => <span className="cursor-pointer" onClick={() => handleEdit(params.row)}> <EditIcon /> </span> },
  ];
  // const rows = tenantData?.map((row) => ({
  //   createdDate : row.createdDate ? row.createdDate.split("T")[0] : "-",
  //   tenantName : row.tenantName,
  //   description : row.description ? row.description : "-",
  //   email : row.email,
  //   phoneNumber : row.phoneNumber,
  //   logoUrl : row.logoUrl ? row.logoUrl : "-" ,
  //   id : row.id,
  //   address: row.address ?  row.address : "-",
  // }))

const handleUpdate=async ()=>{
 setOpenDialog(false);
   await getUpdate({id:mongoId,payload:{...selectedRowData,get_id:""}})
   isRefetchTenants();
}

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={tenantData}
        columns={columns}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Tenant</DialogTitle>
        <DialogContent>
          {selectedRowData && (
            <div className="flex justify-around">
        <div className="flex flex-col justify-center items-end ">
              <TextField
                label="Created Date"
                value={selectedRowData.createdDate}
                fullWidth
                disabled
              />
              <TextField
                label="Tenant Name"
                value={selectedRowData.tenantName}
                fullWidth
                disabled
              />
              <TextField
                label="Description"
                value={selectedRowData.description}
                fullWidth
                disabled
              />
              <TextField
                label="Email"
                value={selectedRowData.email}
                fullWidth
                disabled
              />
              <TextField
                label="Phone Number"
                value={selectedRowData.phoneNumber}
                fullWidth
                disabled
              />
              <TextField
                label="Logo Url"
                value={selectedRowData.logoUrl}
                fullWidth
                onChange={handleUrlChange}
              />
              <TextField
                label="Address"
                value={selectedRowData.address}
                fullWidth
                disabled
              />
              </div>
              </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageTenants;