import api from "../services/index";

const employeeUpdateApi = api.injectEndpoints({
  endpoints: (builder) => ({
     createEmployeeUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/api/employee/updateEmployee?employeeId=${id}`,
        method: "PUT",
        body:  payload ,
      }),
    }),
  }),
});

export default employeeUpdateApi;
export const {  useCreateEmployeeUpdateMutation } = employeeUpdateApi;
