import React from "react";
import { useNavigate } from "react-router-dom";
import arrowHovered from "../../src/Icons/arrowHovered.svg";
import { useSelector } from "react-redux";

const SignInTrackingCard = () => {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    navigate("/employeetracking");
  };

  const handleMoreClick = () => {
    navigate("/employeetracking");
  };

  const { employeeAuditData } = useSelector((state) => state.payroll);
const showShortNames = (name) => {
    const fullName = name?.split(" ");
    const splitName = fullName?.map((part) => part.charAt(0)).join("");
    return splitName?.slice(0, 2);
  };

  const renderNames = (names) => (
    <div className="flex space-x-2">
      {names?.slice(0, 5)?.map((name, index) => {
        return (
          <div key={index+1} className="relative group">
            <div className="w-10 h-10 bg-blue-200 rounded-full flex items-center justify-center text-gray-800">
              {showShortNames(name?.name)}
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100">
              {name?.name}
            </span>
          </div>
        );
      })}
      {names?.length > 5 && (
        <button
          onClick={() => handleMoreClick()}
          className="text-blue-500 hover:underline cursor-pointer"
        >
          +{names?.length - 5} More
        </button>
      )}
    </div>
  );
console.log(employeeAuditData,"EMPLOYEEDATA")
  return (
    <div>
    {Object.keys(employeeAuditData?.data || {}).length === 0 ? (
      <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[90%] bg-blue-50 ml-6 ">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-black font-bold">{employeeAuditData?.message}</h1>
        </div>
      </div>
    ) : (
      <div className="max-w-sm mx-auto my-8 bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Who is in?</h2>
          <button
            onClick={() => handleArrowClick()}
            className="text-gray-500 hover:text-gray-800 transition duration-300 w-16 h-9"
          >
            <img src={arrowHovered} alt="arrowHovered" />
          </button>
        </div>
        <div className="mb-6">
          <p className="text-gray-600 mb-2">
            NOT YET IN ({employeeAuditData?.data?.notyetin?.length})
          </p>
          {renderNames(employeeAuditData?.data?.notyetin)}
        </div>
        <div className="mb-6">
          <p className="text-gray-600 mb-2">
            SIGNED IN ({employeeAuditData?.data?.signedin?.length})
          </p>
          {renderNames(employeeAuditData?.data?.signedin)}
        </div>
      </div>
    )}
  </div>
  
  );
};

export default SignInTrackingCard;
