import React from 'react'
import { Tab } from '@headlessui/react'
import TenantForm from './TenantForm'
import ManageTenants from './ManageTenants'
import { clearCahce } from '../../services/Token'
import { useNavigate } from 'react-router-dom'
import EiLogo from "../../Icons/EiLogo.svg"

const TenatDashboard = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-slate-100 flex flex-col items-center justify-center">
      <div className='w-full h-20 flex justify-between bg-slate-100 border-b border-slate-500'>
        <div className='flex mx-10'>
          <img className="w-10 h-10 my-auto" src={EiLogo} alt="ICON"/>
          <h2 className="text-2xl font-serif text-purple-800 my-auto ml-3 cursor-default">
            <span className="font-extrabold">work</span><span className="font-extrabold">force</span><span className="text-3xl font-extrabold text-blue-600">HR</span>
          </h2>
        </div>
        <p className='my-auto mx-14 text-2xl text-blue-500 cursor-pointer' onClick={() => {
          clearCahce();
          navigate("/login");
        }}>Logout</p>
      </div>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl mt-10 border-black border p-1 w-96 h-12">
          <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-blue-500 shadow text-white'   
                    : 'hover:bg-blue-100 hover:text-blue-700'
                )
              }>Onboarding Form</Tab>
          <Tab className={({ selected }) =>
                classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-blue-500 shadow text-white'
                      : 'text-blue-700 hover:bg-blue-100 hover:text-blue-700'
                )
              }>Manage Tenants</Tab>
        </Tab.List>
        <Tab.Panels className="w-3/4 h-3/4 rounded-xl bg-white border border-black my-10 overflow-hidden">
          <Tab.Panel className="h-full overflow-auto">{<TenantForm />}</Tab.Panel>
          <Tab.Panel className="h-full overflow-auto">{<ManageTenants />}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default TenatDashboard