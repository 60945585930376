import api from "../services";

const getleaveType = api.injectEndpoints({
  endpoints: (builder) => ({
      getLeaveTypes: builder.query({
      query: () => ("/api/leaveCategory/getAllLeaveCategories"),
    }),
  }),
});

export default getleaveType;
export const {  useGetLeaveTypesQuery } = getleaveType;