import api from "../services";

const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
      getAllProjects: builder.query({
      query: () => ("/api/project/getAllProjects"),
      }),
      getAllEmpProjects: builder.query({
      query: () => ("/api/project/getallempsprojects")
      })
  }),
});

export default projectsApi;
export const {  useGetAllProjectsQuery,useGetAllEmpProjectsQuery } = projectsApi;