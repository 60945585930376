import React from 'react'
import TextField from '../../Components/customComponents/customTextField'
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeDetails,updateErrorState , updateErrorStateWork } from "../../store/reducers/addEmployeeSlice"
import { updateErrorMessage } from '../../store/reducers/errorMessageSlice';

const StatutoryInfo = () => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const errorState = useSelector((state)=>state.addEmployee.errorState);
  const errorStateWork = useSelector((state)=>state.addEmployee.errorStateWork);
  const feildDisabled = useSelector((state) => state.addEmployee.feildsDisabled);
  const errorMessageState = useSelector((state)=>state.errorMessage.errorMessage);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate" || 
      e.target.name ==  "employeeDetails.academicDetails.fromDate" ||
      e.target.name ==  "employeeDetails.academicDetails.toDate" 
    ) {
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };

  return (
    <>
    <h1 className='text-2xl mb-10'>  {+empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ? "Step 5: BANK DETAILS" : "Step 4: BANK DETAILS"}</h1>
    <div className='flex flex-col justify-center items-end w-[40%]'>
    <TextField
          name="employeeDetails.bankDetails.bankName"
          label="Bank Name"
          type="text"
          onChange={handleChange}
          value={empState.bankDetails.bankName}
          //isMandatory={true}
          error={  +empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ? (errorState.step4.bankName ? errorState.step4.bankName : ""):(errorStateWork.step3.bankName ? errorStateWork.step3.bankName : "")}
          disabled={feildDisabled}
          onBlur={() => {
            +empState.professionalDetails.totalExperience !== "" &&
            +empState.professionalDetails.totalExperience !== 0 ?
            dispatch(
              updateErrorState({ activeTab: 4, field: "bankName" })
            ): dispatch(
              updateErrorStateWork({ activeTabWork: 3, fieldWork: "bankName" })
            )
          }}
          className = {`${empState.bankDetails.bankName === "" && errorMessageState === true  && "border border-red-500"}`}
        />
        <TextField
          name="employeeDetails.bankDetails.accountNo"
          label="Account Number"
          type="text"
          onChange={handleChange}
          value={empState.bankDetails.accountNo}
          disabled={feildDisabled}
          //isMandatory={true}
          className = {`${empState.bankDetails.accountNo === "" && errorMessageState === true  && "border border-red-500"}`}
        />
        <TextField
          name="employeeDetails.bankDetails.branchName"
          label="Branch Name"
          type="text"
          onChange={handleChange}
          value={empState.bankDetails.branchName}
          //isMandatory={true}
          error={+empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ?(errorState.step4.branchName ? errorState.step4.branchName : ""):(errorStateWork.step3.branchName ? errorStateWork.step3.branchName : "")}
          disabled={feildDisabled}
          onBlur={() => {
            +empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ?
            dispatch(
              updateErrorState({ activeTab: 4, field: "branchName" })
            ):dispatch(
              updateErrorStateWork({ activeTabWork: 3, fieldWork: "branchName" })
            )
          }}
          className = {`${empState.bankDetails.branchName === "" && errorMessageState === true  && "border border-red-500"}`}
        />
        <TextField
          name="employeeDetails.bankDetails.ifsccode"
          label="IFSC Code"
          type="text"
          onChange={handleChange}
          value={empState.bankDetails.ifsccode}
          //isMandatory={true}
          error={+empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ? (errorState.step4.ifsccode ? errorState.step4.ifsccode : ""):(errorStateWork.step3.ifsccode ? errorStateWork.step3.ifsccode : "")}
          disabled={feildDisabled}
          onBlur={() => {
            +empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ?
            dispatch(
              updateErrorState({ activeTab: 4, field: "ifsccode" })
            ): dispatch(
              updateErrorStateWork({ activeTabWork: 3, fieldWork: "ifsccode" })
            )
          }}
          className = {`${empState.bankDetails.ifsccode === "" && errorMessageState === true  && "border border-red-500"}`}
        />
    </div>
    </>
  )
}

export default StatutoryInfo;