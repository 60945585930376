import React, { useEffect, useState } from 'react'
import AttendanceInfo from './AttendanceInfo'
import { MenuItem, Select, FormControl, InputLabel} from '@mui/material';
import { genericHeaders, serviceUrl } from '../../utils/apiConfig';
import axios from 'axios';
import { useFetchAttendanceMutation } from '../../api/loginApi';

const ManagerAttendaceCard = () => {
    
  const [employeeList,setEmployeeList] = useState([]);
  const empIdfromLocalStorage = localStorage.getItem('Id');
  const [employeeId,setEmployeeId] = useState();
  const [fetchAttendance] = useFetchAttendanceMutation();

  const getEmployeeList = async() => {
    const role = localStorage.getItem('roles');
    const url = role === 'MANAGER' ? `${serviceUrl}/api/employee/fetchemployeeslistformanager?managerId=${empIdfromLocalStorage}` : `${serviceUrl}/api/employee/getEmployeesList`;
    try{
      let response = await axios.get(url,{headers: genericHeaders()});
      if(response.status === 200){
        if(role === 'MANAGER'){
           setEmployeeList(transformData(response.data)); 
        }else{
           setEmployeeList(response.data);
        }  
      }
    }catch (err){
      console.error(err);
    }
  }

  const transformData = (data) => {
    let transformedData = Object.keys(data).map((key) => ({
      id: key,
      name: data[key],
    }));
    return transformedData;
  };
  const month = localStorage.getItem("Month");
  const handleEmployeeChange = (e)=>{
    setEmployeeId(e.target);
    localStorage.setItem(employeeId?.value,"Id")
    fetchAttendance({
      employeeId: empIdfromLocalStorage,
      dateTime:month,
    })
  }

  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <section className="mt-24">
      <div className="w-4/5 mx-auto">
        <FormControl className="w-72">
          <InputLabel htmlFor="empId-select-label">
            Select a employee
          </InputLabel>
          <Select
            id="empId-select-label"
            label="Select a Employee ID"
            name="employeeId"
            onChange={(e) => handleEmployeeChange(e)}
            value={employeeId?.name}
            required
            sx={{
              maxHeight: "3rem",
            }}
          >
            {employeeList.length > 0 ? (
              employeeList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem disabled>No Employees to select</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <AttendanceInfo employeeId={employeeId?.value || empIdfromLocalStorage} />
    </section>
  );
}

export default ManagerAttendaceCard