import React from 'react'
import CustomHoliday from './CustomHoliday'
import { TrashIcon } from '@heroicons/react/24/outline';

const ViewCalendar = (props) => {

  return (
    <>
        <section className='ml-10'>
            <div className='flex justify-between w-4/5 pl-5 border-l-4 border-blue-500'>
                <div>
                    <h2 className='text-3xl'>
                        {props.data.calenderName}
                    </h2>
                    <p className='text-lg'>{new Date(props.data.yearOfCalender).getFullYear()}</p>
                </div>
                {
                    props.delete ? 
                    <div className='my-auto mx-5 bg-blue-100 rounded-md cursor-pointer' onClick={() => props.handleDeleteDialog(props.data._id)}>
                        <TrashIcon className="w-12 h-12 p-2" />
                    </div> : 
                    <div></div>
                }
                
            </div>
            <div className='mt-5 space-y-5'>
                {props.data.holidays.map((item,i) => (<CustomHoliday  disabled={true} index={i} year={props.data.yearOfCalender || ""}  date={item.date || ""}  leaveType={item.leaveType} occasion={item.occasion}/>))}
            </div>
        </section>
    </>
  )
}

export default ViewCalendar