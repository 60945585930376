import React from "react";
import { useGetPostsQuery } from "../../services/postsApi";

const Posts = () => {
  const { data, isLoading, isSuccess } = useGetPostsQuery();
  // const [name] = useGetPostsQuery()
  console.log({ data, isLoading, isSuccess }, "jfhskjfhsh");

  return (
    <div style={{ marginTop: "50px" }}>
      {data &&
        data?.map((item) => {
          return <h1 key={item.id}>{item.title}</h1>;
        })}
    </div>
  );
};

export default Posts;
