import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeDetails,setIsUploadDocumentsDialogOpen,setUploadFileObj,updateEmployeeDocs } from "../../store/reducers/addEmployeeSlice";
import TextField from '../../Components/customComponents/customTextField';
import { upload_file_to_s3 } from '../../utils';

export default function UploadDocs() {
    const uploadDocState = useSelector((state) => state.addEmployee.isUploadDocumentDialogOpen);
    const [fileObj,setFileObj] = React.useState({
        "fileName": "",
        "name": "",
        "resourceName": "",
        "uploadDate": "",
        "comment": "",
        "extension": ""
      });
      const [file,setFile]=React.useState("")
    const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(setIsUploadDocumentsDialogOpen(true));
  };

  const handleClose = () => {
    dispatch(setIsUploadDocumentsDialogOpen(false));

  };

  const handleChange = (e) =>{
    if(e.target.name=="name"){
        setFileObj({...fileObj,name:e.target.value});
    }
    else{
        setFile(e.target.files[0]);
    }
   
  }

  const handleUpload = async () => {
    const file_to_upload = file;
    let public_url;
    let document;
    try {
      const s3path = await upload_file_to_s3(
        "cv",
        // new_file.target.files[0],
        file,
        `u_${new Date().getTime()}_cv`
      );
      public_url = s3path.location;
      
    } catch (err) {
      console.error(err);
    }

    
    dispatch(setUploadFileObj({
        ...fileObj,
        fileName: file_to_upload.name, // You can set the name as needed
        resourceName: public_url, // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(), // Extract file extension
    }))
    dispatch(updateEmployeeDocs({value:{
        ...fileObj,
        fileName: file_to_upload.name, // You can set the name as needed
        resourceName: public_url, // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(), // Extract file extension
    },path:"employeeDetails.documents"}))

    dispatch(setIsUploadDocumentsDialogOpen(false));
   
    return document;
  };
  return (
    <div>
      
      <Dialog
        open={uploadDocState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload documents "}
        </DialogTitle>
        <DialogContent>
        <TextField
            name="name"
            label="File Name"
            type="text"

            //value={empState.name}
            onChange={handleChange}
           // isMandatory={true}
          />
          <TextField
            name="file"
            label="Upload file"
            type="file"
           // value={empState.name}
             onChange={handleChange}
           // isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleUpload} autoFocus>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
