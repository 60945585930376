import { configureStore } from "@reduxjs/toolkit";
import rootApi from "../services";
import rootReducer from "./reducers/rootReducer";

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootApi.middleware),
});
