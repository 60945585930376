const storage = localStorage;

export function setToken(key, value) {
  storage.setItem(key, value);
}

export function getToken(key) {
  return storage.getItem(key);
}

export function clearCahce(){
    storage.clear();
}
