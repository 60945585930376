import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { serviceUrl, genericHeaders } from "../../../utils/apiConfig";
import axios, { all } from "axios";
import GeneralStep from "./generalStep";
import CustomTable from "./table";
import PdfPreview from "../pdfPreview";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "80px",
    padding: "0px 100px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["General", "Configuration", "Preview"];
}

function getStepContent(
  step,
  setFormData,
  formData,
  allLetters,
  setAllLetters,
  url,
  setUrl
) {
  switch (step) {
    case 0:
      return (
        <GeneralStep
          setFormData={setFormData}
          formData={formData}
          allLetters={allLetters}
          setAllLetters={setAllLetters}
          setUrl={setUrl}
        />
      );
    case 1:
      return <CustomTable setFormData={setFormData} formData={formData} />;
    case 2:
      return <PdfPreview url={url} />;
    default:
      return "Unknown step";
  }
}

function GenerateLetter() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [formData, setFormData] = useState({});
  const [allLetters, setAllLetters] = useState([]);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const createLetterForEmployee = async (file) => {
    try {
      let data = { ...formData };
      let form = new FormData();
      form.append("file", file);
      // data['file'] = form;
      const url = `${serviceUrl}/api/empLetterMgmt/createEmpLetter`;
      let headers = { ...genericHeaders() };
      // headers["Content-Type"] = "*/*";
      const response = await axios.post(url, data, {
        headers: headers,
      });
      if (response.status === 201) {
        setPreviewUrl(response.data);
      }
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  const saveFileURL = async (body) => {
    try {
      const url = `${serviceUrl}/api/empLetterMgmt/saveFileURL`;
      let headers = { ...genericHeaders() };
      const response = await axios.post(url, body, {
        headers: headers,
      });
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  const getFile = async () => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const file = new File([response.data], "filename.txt", {
        type: "application/pdf",
      });
      setFile(file);
      // createLetterForEmployee(file);
    } catch (error) {
      console.log(error, "error456789");
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (url) {
      getFile();
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 1) {
      createLetterForEmployee();
    }
    if (activeStep === 2) {
      saveFileURL(previewUrl);
      setActiveStep(0);
      setFormData({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleCancel = () => {
    setActiveStep(0);
    setFormData({});
  }

  const { templateId, employeeId } = formData;

  const disableNextButton = () => {
    if ((!templateId || !employeeId) && activeStep == 0) {
      return true;
    }
    return false;
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper
        style={{ background: "transparent" }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(
              activeStep,
              setFormData,
              formData,
              allLetters,
              setAllLetters,
              previewUrl.fileURL,
              setUrl
            )}
          </Typography>
          <div className="mt-10">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
              variant="outlined"
              color="primary"
            >
              Previous
            </Button>
            {isStepOptional(activeStep) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                className={classes.button}
              >
                Cancel
              </Button>
            )}

            <Button
              disabled={disableNextButton()}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateLetter;
