import React from "react";
import { Timeline, TimelineItem, timelineItemClasses, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import dayjs from 'dayjs';

const ViewDetailsOfHistory = (props) => {
  return (
    <div className="flex space-x-10">
      <div className="w-full">
        <div className="flex flex-col space-x-4">
          <h2 className="text-black font-semibold mx-5 my-2">
            {props.data.leaveCategory} Applied on{" "}
            {dayjs(props.data.appliedOn).format("DD MMM YYYY")}
          </h2>
          <div className="flex flex-col border border-gray-300 rounded-2xl bg-white">
            <div className="flex justify-between">
              <div className="flex flex-col justify-between p-2">
                {props.data.status === "APPROVED" ? (
                  <>
                    <p className="text-black pl-4"> Approved by</p>
                    <h1 className="text-black font-bold pl-4">{props.data.managerId}</h1>
                  </>
                ) : props.data.status === "REJECTED" ? (
                  <>
                    <p className="text-black pl-4"> Rejected by</p>
                    <h1 className="text-black font-bold pl-4">{props.data.managerId}</h1>
                  </>
                ) : props.data.status === "PENDING" ? (
                  <>
                    <p className="text-black pl-4"> Pending With</p>
                    <h1 className="text-black font-bold pl-4">{props.data.managerId}</h1>
                  </>
                ) : props.data.status === "CANCELLED" ? (
                  <>
                    <p className="text-black pl-4"> Cancelled By </p>
                    <p className="text-black font-bold pl-4">{props.data.employeeName}</p>
                  </> ) : (
                  ""
                )}
              </div>
              <div className="my-auto">
                <p className="text-green-500 px-4 text-xl">
                  {props.data.status}
                </p>
              </div>
            </div>
            <div className="px-5">
              <div className="bg-yellow-100 my-3 h-20 w-3/4 px-4 rounded-lg flex items-center justify-around">
                <div className="flex flex-col w-[30%]">
                  <p className="text-black">From date</p>
                  <p className="text-black font-bold">
                    {dayjs(props.data.fromDate).format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="flex flex-col w-[30%]">
                  <p className="text-black">To Date</p>
                  <p className="text-black font-bold">
                    {dayjs(props.data.toDate).format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="flex flex-col w-[40%]">
                  <p>No. of days</p>
                  <p className=" font-bold">{props.data.days}</p>
                </div>
              </div>
              <div className="">
                <div className=" w-2/4 my-3 flex flex-row">
                  <p className="text-black font-bold">Balance :</p>
                  <p className="text-black ml-2"> {props.data.availableLeaves} </p>
                </div>
              </div>
            </div>
            <div className="border-t mx-4 my-2 space-y-2 flex flex-col">
              <h1 className="text-black font-bold mt-2"> Details</h1>
              <div className="flex flex-row w-1/2">
                <p className="text-black font-bold"> Applying to</p>
                <h1 className="text-black mx-4">{props.data.managerId}</h1>
              </div>
              <div className="flex">
                <p className="text-black font-bold">Remarks</p>
                <p className="mx-4">{props.data.leaveReason}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border mt-10 border-gray-300 rounded-2xl bg-white w-[25%] h-full mr-4">
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          className="my-6"
        >
          <TimelineItem>
            <TimelineSeparator className="h-44">
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {props.data.status === "APPROVED" ? (
                <>
                  <p className="text-black"> Approved by</p>
                  <p className="text-black font-bold">{props.data.approverName}</p>
                  <p className="text-xs">{dayjs(props.data.approvedDate).format("DD MMM YYYY h:mm A")}</p>
                </>
              ) : props.data.status === "REJECTED" ? (
                <>
                  <p className="text-black"> Rejected by</p>
                  <p className="text-black font-bold">{props.data.declinerName}</p>
                  <p className="text-sm">{dayjs(props.data.declinedDate).format("DD MMM YYYY h:mm A")}</p>
                </>
              ) : props.data.status === "PENDING" ? (
                <>
                  <p className="text-black"> Pending With</p>
                  <p className="text-black font-bold">{props.data.managerId}</p>
                </>
              ) : props.data.status === "CANCELLED" ? (
                <>
                  <p className="text-black"> Cancelled By </p>
                  <p className="text-black font-bold">{props.data.employeeName}</p>
                </> ) : (
                ""
              )}
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              <p>Applied On</p>
              <p>{dayjs(props.data.appliedOn).format("DD/MM/YYYY")}</p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
}

export default ViewDetailsOfHistory