import React, { useEffect, useState } from 'react'
import { useGetAllTrainingModulesQuery } from '../api/TrainingModule';
import Loader from "../Components/customComponents/customLoader"
import downloadIcon from "../Icons/downloadIcon.svg"

const TrainingModules = () => {

    const [trainingData,setTrainingData] = useState();
    const [loading,setLoading] = useState(true);
  
    const { data, isSuccess , isError} = useGetAllTrainingModulesQuery();

    useEffect(() => {
        if(isSuccess){
            setTrainingData(data);
            setLoading(false);
        }
        if(isError){
           setLoading(false); 
        }
    },[isSuccess,isError]);

    const handleDownload = async (url) => {

        const s3Url = url;
        try {
            const response = await fetch(s3Url);
            const blob = await response.blob();
      
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
      
            const urlParts = s3Url.split('/');
            const filename = urlParts[urlParts.length - 1]; //need to change as per filename 
      
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
      
            document.body.removeChild(downloadLink);
          } catch (error) {
            console.error('Error downloading file:', error);
          }
      };
     
  return (
    <div className='mt-28'>
        {loading && <Loader/>}
        <h3 className='m-5 text-2xl font-semibold'>To download the file, click on the respective link provided.</h3>
        <div className="my-auto mx-5 space-y-5">
        {Object.entries(trainingData || {}).map( (item,i) => {
            return (
            <p onClick={() => handleDownload(item[1])} className='text-blue-500 text-lg cursor-pointer w-fit px-3 py-2 border border-blue-500 rounded-md flex'>{item[0]}<img className="w-6 ml-2" src={downloadIcon} alt="ICON"/></p>)
        })}
        </div> 
        
    </div>
  )
}

export default TrainingModules

