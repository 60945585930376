import React, {useState} from "react";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import EditIcon from "@mui/icons-material/Edit";

function PersonalDetails(props) {
  // const Id = getToken("Id");
  // const { data, isSuccess } = useCreateProfileQuery({
  //   employeeId: `${Id}`,
  // });

  return (
    <div className="w-full">
      <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
        <div>
          <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
            Personal Information
            <span className={`mx-2 cursor-pointer ${
                      props.edit.personalInformation ? "hidden" : ""
                    }`}
                    onClick={() =>
                      props.setEdit((prevEdit) => ({
                        ...prevEdit,
                        personalInformation : true,
                      }))
                    }
                  >
                    {<EditIcon />}
            </span>
          </h3>

          {
            props.edit.personalInformation ?
              <EditPersonalDetails
                data={props.data}
                setEmployee={props.setEmployee}
                edit={props.edit}
                setEdit={props.setEdit}
                save={props.save}
                oldData={props.oldData}
              /> :
              <>
                <div className="grid grid-cols-4 p-2 gap-2 mb-10 ">
                  <div>
                    <h4 className="text-slate-600">DOB</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.dob
                        ? dayjs(props.data?.personalDetails?.dob).format("DD MMM YYYY")
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Blood Group</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.bloodGroup
                        ? props.data?.personalDetails?.bloodGroup
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Father Name</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.fatherName
                        ? props.data?.personalDetails?.fatherName
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Marital Status</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.maritalStatus
                        ? props.data?.personalDetails?.maritalStatus
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Spouse Name</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.spouseName
                        ? props.data?.personalDetails?.spouseName
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Nationality</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.nationality
                        ? props.data?.personalDetails?.nationality
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">International Employee</h4>
                    <p className="my-1 text-lg">
                      {" "}
                      {props.data?.personalDetails?.internationalEmployee !== undefined
                        ? props.data?.personalDetails?.internationalEmployee
                          ? "Yes"
                          : "No"
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Physically Challenged</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.physicallyDisabled !== undefined
                        ? props.data.personalDetails.physicallyDisabled
                          ? "Yes"
                          : "No"
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Personal Email</h4>
                    <p className="my-1 text-lg">
                      {props.data?.personalDetails?.email
                        ? props.data?.personalDetails?.email
                        : "-"}
                    </p>
                  </div>
                </div>
              </>
            } 
        </div>
      </div>
    </div>
    
          
  );
}
export default PersonalDetails;

export const EditPersonalDetails = (props) => {

  const [error,setError] = useState({email : "",});
  let formData = cloneDeep(props.data);
  const regex = /^[a-zA-Z\s]+$/
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError({email : "Enter valid email",});
      }else{
        setError({email : "",});
      }
      newData.personalDetails.email = e.target.value;
    }else if(e.target.name === "dob"){
      let date = new Date(e.target.value).toISOString();
      newData.personalDetails.dob = date;
    }else if(e.target.name === "internationalEmployee" || e.target.name === "physicallyDisabled"){
      let value;
      e.target.value === "true" ? value = true : value = false;
      newData.personalDetails[e.target.name] = value;      
    }else if(e.target.name === "bloodGroup"){
       if(/^[a-zA-Z+-]+$/.test(e.target.value) || e.target.value === ""){
         newData.personalDetails[e.target.name] = e.target.value;
       }
    }else{
      if (regex.test(e.target.value) || e.target.value === "") {
        newData.personalDetails[e.target.name] = e.target.value;
      }
    }
    props.setEmployee(newData);
  };

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">DOB</h4>
          <input
            type="date"
            name="dob"
            max={new Date().toISOString().split("T")[0]}
            value={
              dayjs(formData?.personalDetails?.dob).format("YYYY-MM-DD") || ""
            }
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Blood Group</h4>
          <input
            type="text"
            name="bloodGroup"
            value={formData?.personalDetails?.bloodGroup || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Father Name</h4>
          <input
            type="text"
            name="fatherName"
            value={formData?.personalDetails?.fatherName || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Marital Status</h4>
          <select
            name="maritalStatus"
            value={formData?.personalDetails?.maritalStatus || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          >
            <option value="SINGLE">SINGLE</option>
            <option value="MARRIED">MARRIED</option>
            <option value="DIVORCED">DIVORCED</option>
            <option value="SEPARATED">SEPARATED</option>
            <option value="WIDOWED">WIDOWED</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Spouse Name</h4>
          <input
            type="text"
            name="spouseName"
            value={formData?.personalDetails?.spouseName || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Nationality</h4>
          <select
            name="nationality"
            value={formData?.personalDetails?.nationality|| ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          >
            <option value="INDIAN">INDIAN</option>
            <option value="OTHERS">OTHERS</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">International Employee</h4>
          <select
            name="internationalEmployee"
            value={formData?.personalDetails?.internationalEmployee}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          >
            <option value={""} disabled>--Select an option--</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Physically Challenged</h4>
          <select
            name="physicallyDisabled"
            value={formData?.personalDetails?.physicallyDisabled}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          >
            <option value={""} disabled>--Select an option--</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Personal Email</h4>
          <input
            type="email"
            name="email"
            value={formData?.personalDetails?.email || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
          {error.email !== "" && (
            <p className="text-red-500 text-xs">{error.email}</p>
          )}
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button
          className="border px-2  rounded-md"
          onClick={props.save}
          disabled={error.email !== ""}
        >
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({ ...prevEdit, personalInformation: false }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}