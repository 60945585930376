import api from "../services/index";

const leaveType = api.injectEndpoints({
  
 endpoints: (builder) => ({
  getAllLeaveCategories: builder.query({
   query: (params) => ({
     url: `/api/leaveCategory/getAllLeaveCategories`,
     method: "get",
     params
   }),
 }),
}),
});


export default leaveType;
export const { useGetAllLeaveCategoriesQuery } = leaveType;