// Function to convert a number into words
const numberToWords = (number) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
  
    // Function to convert numbers less than 1000 into words
    const convertLessThanThousand = (num) => {
      if (num === 0) {
        return '';
      } else if (num < 10) {
        return units[num];
      } else if (num < 20) {
        return teens[num - 10];
      } else if (num < 100) {
        return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
      } else {
        return units[Math.floor(num / 100)] + ' Hundred ' + convertLessThanThousand(num % 100);
      }
    };
  
    if (number === 0) {
      return 'Zero';
    }
  
    let words = '';
    let i = 0;
  
    while (number > 0) {
      if (number % 1000 !== 0) {
        words = convertLessThanThousand(number % 1000) + ' ' + thousands[i] + ' ' + words;
      }
      number = Math.floor(number / 1000);
      i++;
    }
  
    return words.trim();
  };
  export default numberToWords
  