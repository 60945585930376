import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";

function handleNumericKeyDown(event) {
  if (
    event.keyCode === 187 ||
    event.keyCode === 189 ||
    event.keyCode === 190 ||
    event.keyCode === 69  ||
    event.keyCode === 109 ||
    event.keyCode === 110 ||
    event.keyCode === 107
  ) {
    event.preventDefault();
  }
}

function TextField({
  label,
  value,
  onChange,
  type,
  disabled,
  name,
  max,
  min,
  placeHolder,
  isMandatory,
  error,onBlur,className
})
{
  const onKeyDownHandler = type === "number" ? handleNumericKeyDown : null;

  return (
    <div className="mb-4 flex items-center">
      <label className="block text-sm font-medium text-gray-700  mr-5">
        {label} {isMandatory && <span className="text-red-500 text-xl">*</span>}
      </label>
<div className="flex flex-col">
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        className={`w-60 px-3 py-2 border  rounded-md shadow-sm focus:ring-blue-500 ${className}`}
        disabled={disabled}
        placeholder={placeHolder}
        max={max}
        onBlur={onBlur}
        min={min}
        onKeyDown={onKeyDownHandler}
      />
     {error && <p className="text-red-500 text-sm italic">{error}</p>}
     </div>
    </div>
  );
}

export default TextField;
