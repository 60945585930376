import React from "react";
import CustomRadioSelect from "../../customComponents/customRadioSelection";
import CustomFieldTable from "./CustomFieldsTable";

const WorkflowSetup = ({ filePlaceholders,setFormData,formData }) => {
  return (
    <div>
      <h3 className="text-xl my-2 p-2 font-semibold">Step 3: Workflow Setup</h3>

      <CustomFieldTable setFormData={setFormData} formData={formData} filePlaceholders={filePlaceholders} />
    </div>
  );
};

export default WorkflowSetup;
