import React, { useState} from "react";
import { Select, MenuItem } from "@mui/material";
import { useGetEmployeesListQuery } from "../../api/getEmployeeList";
import "../../css/dropdown.css";
import { useGetAllEmployeeLeavesQuery } from "../../api/employeeLeaves";
import { v4 as uuidv4 } from "uuid";
import { useGetAllLeaveCategoriesQuery } from "../../api/leaveploicyApi";
import { genericHeaders, serviceUrl } from "../../../src/utils/apiConfig";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { getToken } from "../../services/Token";

export default function Leavepolicy() {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [editedRowId, setEditedRowId] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);

  const handleClickOpenDialog = (rowIndex) => {
    setOpenDialog(rowIndex);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
    setIsEditing(false);
  };

  const [employeePayload, setEmployeePayload] = useState([
    {
      isActive: true,
      leaveType: "",
      currentAvailableLeaves: 0,
      leavesObtained: 0,
      leavesAvailed: 0,
      leavesApproved: 0,
      leavesRejected: 0,
    },
  ]);

  const { data: employeeData, isSuccess: employeesIsSuccess } =
    useGetEmployeesListQuery();
  let {
    data: leavesData,
    isSuccess: leavesIsSuccess,
    refetch,
  } = useGetAllEmployeeLeavesQuery();
  const { data: leaveTypeData, isSuccess: leaveTypeIsSuccess } =
    useGetAllLeaveCategoriesQuery();

  leavesData = leavesData
    ? leavesData.map((leave) => ({
        id: uuidv4(),
        ...leave,
      }))
    : [];

  const [employeeType, setEmployeeType] = useState("currentEmployee");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleChange = (event) => {
    setEmployeeType(event.target.value);
  };

  let filteredLeavesData = selectedEmployee
    ? leavesData.find((leave) => leave.employeeId === selectedEmployee.id)
    : [];
  const leaveId = filteredLeavesData ? filteredLeavesData._id : "";
  const selectedEmployeeData =
    filteredLeavesData ? filteredLeavesData : null;

  const rows =
    selectedEmployeeData && selectedEmployeeData.leaves
      ? selectedEmployeeData.leaves.map((leave, index) => ({
          id: index,
          leaveType: leave.leaveType,
          currentAvailableLeaves: leave.currentAvailableLeaves,
          leavesObtained: leave.leavesObtained,
          leavesAvailed: leave.leavesAvailed,
          leavesApproved: leave.leavesApproved,
          leavesRejected: leave.leavesRejected,
        }))
      : [];
  const columns = [
    {
      field: "leaveType",
      headerName: "Leave Type",
      width: 150,
    },
    {
      field: "currentAvailableLeaves",
      headerName: "Current Available Leaves",
      width: 200,
    },
    {
      field: "leavesObtained",
      headerName: "Leaves Obtained",
      width: 150,
    },
    {
      field: "leavesAvailed",
      headerName: "Leaves Availed",
      width: 150,
    },
    {
      field: "leavesApproved",
      headerName: "Leaves Approved",
      width: 150,
    },
    {
      field: "leavesRejected",
      headerName: "Leaves Rejected",
      width: 150,
    },
  ];

  const transactionColumns = [
    {
      field: "updatedBy",
      headerName: "Updated By",
    },
    {
      field: "leaveType",
      headerName: "Leave Type",
    },
    {
      field: "changedOn",
      headerName: "Changed On",
    },  
    {
      field: "from",
      headerName: "From",
    },
    {
      field: "to",
      headerName: "To",
    },
  ];

  const handleChangeEmployee = (event) => {
    const selectedId = event.target.value;
    const employee = employeeData.find((emp) => emp.id === selectedId);
    setSelectedEmployee(employee);
  };
  const handleEditClick = (rowIndex, rowData) => {
    setIsEditing(true);
    setEditedRowId(rowIndex);
    setEditedValue(rowData.currentAvailableLeaves.toString());
  };

  const handleSaveEdit = (rowIndex) => {
    const newValue = parseFloat(editedValue);
    if (!isNaN(newValue)) {
      // Set the edited data and open the dialog
      setEditedRowId(rowIndex);
      setEditedValue(newValue);
      handleClickOpenDialog(rowIndex);
    } else {
      // Handle validation error, if needed
    }
  };
  const saveEditedData = async (updatedData) => {
    const updatedEmployeePayload = updatedData.map((row) => ({
      isActive: "true",
      leaveType: row.leaveType,
      currentAvailableLeaves: row.currentAvailableLeaves,
      leavesObtained: row.leavesObtained,
      leavesAvailed: row.leavesAvailed,
      leavesApproved: row.leavesApproved,
      leavesRejected: row.leavesRejected,
    }));

    try {
      let response = await axios.put(
        `${serviceUrl}/api/employeeLeave/updateEmployeeLeave?employeeLeaveId=${leaveId}`,
        updatedEmployeePayload,
        { headers: genericHeaders() }
      );
      setEmployeePayload(updatedEmployeePayload);
      refetch();
      setOpenDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmSave = async () => {
    handleCloseDialog();

    const updatedRows = [...rows];
    updatedRows[editedRowId].currentAvailableLeaves = editedValue;

    rows[editedRowId].currentAvailableLeaves = editedValue;
    saveEditedData(updatedRows);
    setIsEditing(false);
  };
  
  //to disaplay the data under leave transcations
  let leaveTranscations = cloneDeep(filteredLeavesData?.leaveEventRelationShip);
  leaveTranscations = leaveTranscations?.map((item) => {
    let updatedEmployee = employeeData?.find((emp) => emp.id === item.eventActor);
    let updatedLeaveType = leaveTypeData?.find((leave) => leave._id === item.leaveType)
    item['eventActor'] = updatedEmployee?.name;
    item['leaveType'] = updatedLeaveType?.leaveCategory;
    return item;
  });

  return (
    <>
      <div className="flex flex-col border border-gray-300 rounded-2xl bg-white w-[90%] ml-4 mt-[8rem]">
        <div className="flex flex-row justify-between pt-4 mb-4">
          <h1 className="text-black pl-4 font-bold">
            Start searching to see specific employee details here
          </h1>
        </div>
        <div className="flex flex-row">
          <h1 className="text-gray-600 pt-2 pl-4"> Employee Type: </h1>
          <h1 className="ml-4">
            <Select
              id="employeeType"
              name="employeeType"
              value={employeeType}
              onChange={handleChange}
              style={{
                borderColor: "gray",
                marginTop: "8px",
                width: "300px",
                marginLeft: "14px",
                borderRadius: "22px",
                height: "40px",
              }}
            >
              <MenuItem value="currentEmployee">Current Employee</MenuItem>
              {/* <MenuItem value="resignedEmployee">Resigned Employee</MenuItem> */}
            </Select>
          </h1>
        </div>
        <h1 className="text-gray-600 pt-2 pl-4">Employee List</h1>
        {employeesIsSuccess && (
          <Select
            id="employeeList"
            name="employeeList"
            value={selectedEmployee ? selectedEmployee.id : "Select Employee"}
            onChange={handleChangeEmployee}
            className="my-2"
            MenuProps={{
              sx: {
                "& .MuiList-root": {
                  maxHeight: 300,
                },
                "& .MuiInputBase-root": {
                  height: 50,
                },
              },
            }}
            style={{
              borderColor: "gray",
              marginTop: "8px",
              width: "300px",
              marginLeft: "14px",
              borderRadius: "22px",
              height: "40px",
            }}
          >
            {employeeData.filter((item) => item.id !==  getToken("Id")).map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <h1 className="mt-12 text-xl font-bold ml-4">Overview:</h1>
      <div className="mt-4">
        {leavesIsSuccess && (
          <div style={{ minheight: 400, width: "85%", marginLeft: "20px" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.field}
                      style={{
                        borderBottom: "2px solid lightgray",
                        padding: "8px",
                        backgroundColor: "lightblue", // Light blue background color for headers
                      }}
                    >
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column) => (
                      <td
                        key={column.field}
                        style={{
                          borderBottom: "2px solid lightgray",
                          borderRight: "none",
                          padding: "8px",
                          backgroundColor:
                            isEditing &&
                            editedRowId === rowIndex &&
                            column.field === "currentAvailableLeaves"
                              ? "lightyellow"
                              : "white",
                        }}
                      >
                        {isEditing &&
                        editedRowId === rowIndex &&
                        column.field === "currentAvailableLeaves" ? (
                          <input
                            type="text"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:ring-blue-400"
                          />
                        ) : column.field === "leaveType" ? (
                          leaveTypeData.find(
                            (item) => item._id === row[column.field]
                          )?.leaveCategory ?? ""
                        ) : (
                          row[column.field]
                        )}
                      </td>
                    ))}
                    <td>
                      {isEditing && editedRowId === rowIndex ? (
                        <button
                          className="ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-400"
                          onClick={() => handleSaveEdit(rowIndex)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-400"
                          onClick={() => handleEditClick(rowIndex, row)}
                        >
                          Edit
                        </button>
                      )}
                      <Dialog
                        open={openDialog === rowIndex}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>Confirm Save</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to save this change?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={handleConfirmSave} color="primary">
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <h1 className="mt-12 text-xl font-bold ml-4">Leave Transcations:</h1>
      <div className="mt-4">
        {leavesIsSuccess && (
          <div style={{ minheight: 400, width: "85%", marginLeft: "20px" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                borderRadius: "10px",
                tableLayout: "fixed"
              }}
            >
              <thead>
                <tr>
                  {transactionColumns.map((column) => (
                    <th
                      key={column.field}
                      style={{
                        borderBottom: "2px solid lightgray",
                        padding: "8px",
                        textAlign: 'center',
                        backgroundColor: "lightblue", // Light blue background color for headers
                      }}
                    >
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {leaveTranscations?.map((item) => (
                  <tr key={item?.timeStamp} className="text-center my-2">
                    <td>{item?.eventActor || "-"}</td>
                    <td>{item?.leaveType || "-"}</td>
                    <td>{dayjs(item?.timeStamp).format("DD MMM YYYY") || "-"}</td>
                    <td>{item?.previousLeaves ?? "-"}</td>
                    <td>{item?.updatedleaves ?? "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
