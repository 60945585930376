import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tableData : [],
};

  const generateLetterSlice = createSlice({
    name: "generateLetter",
    initialState,
    reducers: {
      updateTableData: (state, action) => {
        state.tableData = action.payload;
      }
    },
  });
  
  export const {
  updateTableData
  } = generateLetterSlice.actions;
  
  export default generateLetterSlice.reducer;
  