import api from "../services";

const getEmployeeList = api.injectEndpoints({
    endpoints : (builder) => ({
        getEmployeesList : builder.query({
            query : () => ("/api/employee/getEmployeesList"),
        })
    })
})

export default getEmployeeList;
export const { useGetEmployeesListQuery } = getEmployeeList;