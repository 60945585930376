import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { getToken } from '../services/Token';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import LeaveComponent from './LeaveComponent';
import NoContent from '../Icons/No-Content-Laptop.svg'
import Loader from "../Components/customComponents/customLoader" 

const LeavesHistory = () => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([])
    const [leaveData,setLeaveData] = useState([]);
    const [history,setHistory] = useState([]);
    const [employees,setEmployees] = useState([]);
    const Id = getToken('Id');
    const getData = async() => {
      setLoading(true);
      try{
          let response = await axios.get(`${serviceUrl}/api/leaveTransaction/getEmpLeaveHistory?employeeId=${Id}`,{headers : genericHeaders()})
          if(response.status === 200){
            setData(response.data);
            setLoading(false);
          }
      } catch (err){
          console.error(err)
      }
    }
    const getLeaveData = async() => {
      try{
        let response = await axios.get(`${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,{headers: genericHeaders()});
        if(response.status === 200){
          setLeaveData(response.data);
        }

      }catch(err){
          console.error(err)
      }
    }
    const getEmployees = async() => {
      try{
        let response = await axios.get(`${serviceUrl}/api/employee/getEmployeesList`,{headers: genericHeaders()})
        if(response.status === 200){
          setEmployees(response.data);
        }
      } catch(err){
        console.error(err);
      }
    }
    useEffect(() => {
      getData();
      getLeaveData();
      getEmployees();
    }, []);
    useEffect( () => {
      if(data && leaveData && employees){
       let newData = matchData(data,leaveData,employees);
       setHistory(newData);
      }
   },[data,leaveData,employees])
    const matchData = (employeeLeaves, leaveData, empData) => {
      let data = [];
      if (employeeLeaves && leaveData) {
          employeeLeaves.map( (item) => {
            let matchedLeave = leaveData.find( (leave) => leave._id === item.leaveType);
            if(matchedLeave){
              item.leaveCategory = matchedLeave.leaveCategory;
            }
            if(item.employeeName === null){
              let matchedEmployee = empData.find( (emp) => emp.id === item.employeeId);
              if(matchedEmployee){
                item.employeeName = matchedEmployee.name;
              }
            }
            if(item.declinerName !== null){
              let matchedEmployee = empData.find( (emp) => emp.id === item.declinerName);
              if(matchedEmployee){
                item.declinerName = matchedEmployee.name;
              }
            }
            if(item.approverName !== null){
              let matchedEmployee = empData.find( (emp) => emp.id === item.approverName);
              if(matchedEmployee){
                item.approverName = matchedEmployee.name;
              }
            }
            data.push(item);
        })
      }
      return data;
    };
    return (
      <div>
        {loading && <Loader/>}
        {history.length > 0 ? (
          history.map((item) => <LeaveComponent data={item} />)
        ) : (
          <div className="mt-[15%] flex flex-col items-center justify-center">
            <img src={NoContent} alt='NO Content'/>
            <p className="text-xl">
              No historical records of leave transactions are available.
            </p>
          </div>
        )}
      </div>
    );
}
export default LeavesHistory