import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Tooltip } from "@mui/material";

const WeekCard = (props) => {

    const {item,daysVisible,weekIndex,toggleDaysVisibility,datesData,getLogHoursValue,getCommentsValue,isEditable,isAdmin,handleCommentChange,handleHourChange,handleSubmit,timeSheet,setTimeSheet} = props;
    const exceedsWordLimit = (text) => {
      if (text) {
        const words = text.split("").length;
        return words > 25;
      }
      return false;
    };

  return (
    <div
      key={item?.firstDay.toString()}
      className="mx-auto w-4/5 rounded-xl bg-white border border-black mb-5"
    >
      <div
        className={`w-full h-fit flex justify-between bg-blue-100 ${
          daysVisible[weekIndex] ? "rounded-t-xl" : "rounded-xl"
        }`}
      >
        <h2 className="text-2xl font-semibold p-3 items-start">
          {item?.firstDay} to {item?.lastDay}
        </h2>
        <button
          className="my-auto mx-5 cursor-pointer"
          onClick={() => toggleDaysVisibility(weekIndex)}
        >
          {daysVisible[weekIndex] ? (
            <div className="flex space-x-5">
              <p className="text-lg text-blue-400">Close Timesheet</p>
              <ChevronDoubleUpIcon className="h-7 text-black cursor-pointer" />
            </div>
          ) : (
            <div className="flex space-x-5">
              <p className="text-lg text-blue-400">Fill Timesheet</p>
              <ChevronDoubleDownIcon className="h-7 text-black cursor-pointer" />
            </div>
          )}
        </button>
      </div>

      <div className={`${daysVisible[weekIndex] ? "max-h-fit" : "hidden"}`}>
        {datesData.length > 0 ? (
          datesData[weekIndex].map((item) => {
            const [day, month, year] = item.split("/");
            const date = new Date(year, month - 1, day);
            const currentDate = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000
            );
            return (
              <div
                className={`grid grid-cols-3 space px-10 py-5 overflow-hidden   border-t-2`}
              >
                <p className="text-xl font-semibold my-auto ">{item}</p>
                {isEditable ? (
                  <>
                    <div>
                      <p className="text-[10px]">Enter log hours:</p>
                      <input
                        id={currentDate.toISOString()}
                        placeholder="Enter working hours"
                        className="w-60 px-3 py-2 border rounded-sm bg-gray-100"
                        onChange={(e) => handleHourChange(e,timeSheet,setTimeSheet)}
                        value={getLogHoursValue(
                          currentDate.toISOString(),
                          timeSheet
                        )}
                        type="number"
                      />
                    </div>
                    <div>
                      <p className="text-[10px]">Enter Comments:</p>
                      <input
                        id={currentDate.toISOString()}
                        className="w-60 px-3 py-2 border rounded-sm bg-gray-100"
                        onChange={(e) => handleCommentChange(e,timeSheet,setTimeSheet)}
                        value={getCommentsValue(
                          currentDate.toISOString(),
                          timeSheet
                        )}
                        type="text"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-lg my-auto">
                      Worked Hours :{" "}
                      {getLogHoursValue(currentDate.toISOString(), timeSheet)}
                    </p>
                    <p className="text-lg my-auto">
                      Comments :{" "}
                      <Tooltip
                        title={getCommentsValue(
                          currentDate.toISOString(),
                          timeSheet
                        )}
                        arrow
                        placement="top"
                      >
                        <span className="text-sm">
                          {exceedsWordLimit(
                            getCommentsValue(
                              currentDate.toISOString(),
                              timeSheet
                            )
                          )
                            ? getCommentsValue(
                                currentDate.toISOString(),
                                timeSheet
                              ).slice(0, 25) + "..."
                            : getCommentsValue(
                                currentDate.toISOString(),
                                timeSheet
                              ) || "--"}
                        </span>
                      </Tooltip>
                    </p>
                  </>
                )}
              </div>
            );
          })
        ) : (
          <></>
        )}
        <div
          className={` justify-end my-5 w-[95%] ${isEditable && !isAdmin ? "flex" : "hidden"}`}
        >
          <button
            className="bg-blue-500 hover:bg-white hover:text-blue-500 border border-blue-500 text-white font-bold py-1 px-2 rounded"
            onClick={() => handleSubmit(false)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default WeekCard;
