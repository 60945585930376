import React from "react";
import TextField from "../../Components/customComponents/customTextField";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmployeeDetails,
  updateErrorState,
} from "../../store/reducers/addEmployeeSlice";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";

const StatutoryInfo = () => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const errorState = useSelector((state) => state.addEmployee.errorState);
  const feildDisabled = useSelector(
    (state) => state.addEmployee.feildsDisabled
  );
  const errorMessageState = useSelector(
    (state) => state.errorMessage.errorMessage
  );
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate" ||
      e.target.name == "employeeDetails.academicDetails.fromDate" ||
      e.target.name == "employeeDetails.academicDetails.toDate"
    ) {
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };
  return (
    <>
      <h1 className="text-2xl mb-10">Step 3: ACADEMIC DETAILS</h1>
      <div className="flex flex-col justify-center items-end w-[40%]">
        <TextField
          name="employeeDetails.academicDetails.institute"
          label="College Name"
          type="text"
          onChange={handleChange}
          //isMandatory={true}
          value={empState.academicDetails.institute}
          error={errorState.step2.institute ? errorState.step2.institute : ""}
          disabled={feildDisabled}
          onBlur={() => {
            dispatch(updateErrorState({ activeTab: 2, field: "institute" }));
          }}
          className={`${
            empState.academicDetails.institute === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.qualification"
          label="Qualification"
          type="text"
          onChange={handleChange}
          //isMandatory={true}
          value={empState.academicDetails.qualification}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.qualification === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.grade"
          label="Grade"
          type="text"
          onChange={handleChange}
          //isMandatory={true}
          value={empState.academicDetails.grade}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.grade === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.fromDate"
          label="From Date"
          type="date"
          onChange={handleChange}
          //isMandatory={true}
          value={
            empState.academicDetails.fromDate
              ? empState.academicDetails.fromDate.split("T")[0]
              : ""
          }
          max={empState.academicDetails.toDate ? (() => {
            const toDate = new Date(empState.academicDetails.toDate);
            toDate.setDate(toDate.getDate() - 1);
            return toDate.toISOString().split("T")[0];
          })()
            : new Date().toISOString().split("T")[0]}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.fromDate === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.toDate"
          label="To Date"
          type="date"
          onChange={handleChange}
          //isMandatory={true}
          value={
            empState.academicDetails.toDate
              ? empState.academicDetails.toDate.split("T")[0]
              : ""
          }
          max={new Date().toISOString().split("T")[0]}
          min={
            empState.academicDetails.fromDate
              ? (() => {
                  const fromDate = new Date(empState.academicDetails.fromDate);
                  fromDate.setDate(fromDate.getDate() + 1);
                  return fromDate.toISOString().split("T")[0];
                })()
              : ""
          }
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.toDate === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
      </div>
    </>
  );
};

export default StatutoryInfo;
