import api from "../services/index";

const addTenantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTenantUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/api/tenant/create`,
        method: "POST",
        body: payload,
      }),
    }),
    getAllTenants: builder.query({
      query: () => "api/tenant/getAllTenants",
    }),
    getTenantDetails: builder.query({
      query: (payload ) => ({
        url: `api/tenant/fetchtenant?tenantMongoId=${payload}`,
        method: "GET",
      }),
    }),
getUpdate:builder.mutation({
  query:({id,payload})=>({
    url:`/api/tenant/updatetenant?tenantMongoId=${id}`,
    method:"PUT",
    body:payload
  })
}),
  }),
});

export default addTenantApi;
export const { useCreateTenantUpdateMutation, useGetAllTenantsQuery,useLazyGetTenantDetailsQuery,useGetUpdateMutation } = addTenantApi;
