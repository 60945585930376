import api from "../services";
import { getToken } from "../services/Token";


const calendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
      getholidayCalendars: builder.query({
      query: () => ("/api/holidayCalender/getAllHolidayCalenders"),
      }),
      getEmployeeCalendar: builder.query({
        query: () => (`/api/holidayCalender/getempholidaycalender?employeeId=${getToken('Id')}`)
      })
  }),
});

export default calendarApi;
export const {  useGetholidayCalendarsQuery,useGetEmployeeCalendarQuery } = calendarApi;