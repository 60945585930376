import { createSlice } from "@reduxjs/toolkit";
import addEmployeeJson from "../../pages/addEmployee/addEmployee.json";
import { validateMobileNumber } from "../../utils";
import { getToken } from "../../services/Token";

const initialState = {
  addEmployeeClicked: false,
  employeeDetails: addEmployeeJson,
  isUploadDocumentDialogOpen: false,
  uploadFileObj: {},
  errorState: { step0: {}, step1: {}, step2: {}, step4:{} },
  errorStateWork: { step0: {}, step1: {}, step2: {}, step3:{}},
  isErrorFields: false,
  successOpen : false,
  employeeloginId :"",
  feildsDisabled : false
};
const addEmployeeSlice = createSlice({
  name: "addEmployee",
  initialState,
  reducers: {
    updateAddEmployeeClicked: (state, action) => {
      state.addEmployeeClicked = action.payload;
    },
    updateEmployeeJson: (state,action) => {
      state.employeeDetails = action.payload;
    },
    updateEmployeeDetails: (state, action) => {
      const { value, path } = action.payload;
      const pathSegments = path.split(".");
      for (const pathSegment of pathSegments) {
        if (pathSegment === pathSegments[pathSegments.length - 1]) {
          state[pathSegment] = value;
        } else {
          state = state[pathSegment];
        }
      }
      //state.employeeDetails["name"]=action.payload;
    },
    updateEmployeeWorkExperience: (state, action) => {
      const { value, path ,index} = action.payload;
      state.employeeDetails.workExperience[index][path] = value;
    },
    addWorkExperience:(state,action)=>{
      let workExperience={
        "companyName": "",
        "designation": "",
        "startDate": "",
        "endDate": "",
        "documents": []
      }
      state.employeeDetails.workExperience.push(workExperience);
    },
    updateEmployeeDocs: (state, action) => {
      const { value } = action.payload;
      // if(!state.employeeDetails.documents[0]["resourceName"]){
      //   state.employeeDetails.documents.shift();
      // }
      state.employeeDetails.documents.push(value);
    },
    setIsUploadDocumentsDialogOpen: (state, action) => {
      state.isUploadDocumentDialogOpen = action.payload;
    },
    clearEmployeeDetails: (state, action) => {
      state.employeeDetails = action.payload;
    },
    setUploadFileObj: (state, action) => {
      state.uploadFileObj = action.payload;
    },
    deleteOtherDocsFiles: (state, action) => {
      state.employeeDetails.documents.splice(action.payload, 1);
    },
    updateErrorStateWork: (state,action) =>{
      const { activeTabWork, fieldWork } = action.payload;
      if (fieldWork === "bankName") {
        if (
          !(/^[A-Za-z ]+$/.test(state.employeeDetails.bankDetails.bankName)) &&
          state.employeeDetails.bankDetails.bankName.length > 0 &&
          activeTabWork === 3
        ) {
          state.errorStateWork.step3["bankName"] = "Invalid Bank Name";
        } else {
          delete state.errorStateWork.step3["bankName"];
        }
      }
      if (fieldWork === "branchName") {
        if (
          !(/^[a-zA-Z0-9\s]*$/.test(state.employeeDetails.bankDetails.branchName)) &&
          state.employeeDetails.bankDetails.branchName.length > 0 &&
          activeTabWork === 3
        ) {
          state.errorStateWork.step3["branchName"] = "Invalid Branch Name";
        } else {
          delete state.errorStateWork.step3["branchName"];
        }
      }
      if (fieldWork === "ifsccode") {
        if (
          !(/^[A-Za-z]{4}0[A-Z0-9]{6}$/.test(state.employeeDetails.bankDetails.ifsccode)) &&
          state.employeeDetails.bankDetails.ifsccode.length > 0 &&
          activeTabWork === 3
        ) {
          state.errorStateWork.step3["ifsccode"] = "Invalid IFSC code";
        } else {
          delete state.errorStateWork.step3["ifsccode"];
        }
      }
    },
    updateErrorState: (state, action) => {
      const { activeTab, field } = action.payload;
      const companyEmail = getToken('professionalEmail');
      const isEmailValidationEnabled = getToken("isEmailValidationEnabled");
      const parts = companyEmail.split('@');
      const domain = parts[1];
      let emailPattern;
      if(isEmailValidationEnabled === 'true'){
        let emailRegex = `^[A-Z0-9._%+-]+@${domain.replace('.', '\\.')}`
        emailPattern = new RegExp(emailRegex, 'i');
      }else{
        emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      }
      const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      if (field === "companyEmail" && state.employeeDetails?.professionalDetails?.companyEmail !== "" ) {
        if (
          !emailPattern.test(
            state.employeeDetails.professionalDetails.companyEmail
          )
        ) {
        state.errorState.step0["companyEmail"] = "Invalid Company Email";
      } else {
        delete state.errorState.step0["companyEmail"];
        }
      }else{
        delete state.errorState.step0["companyEmail"];
      }
      if (field === "aadhar") {
        if (
          state.employeeDetails.personalDetails.aadharNumber.length < 12 &&
          state.employeeDetails.personalDetails.aadharNumber.length > 0 &&
          activeTab !== 0
        ) {
          state.errorState.step1["aadhar"] = "Invalid aadhar";
        } else {
          delete state.errorState.step1["aadhar"];
        }
      }
      if (field === "pan") {
        if (
          !panPattern.test(state.employeeDetails.personalDetails.panNumber) &&
          state.employeeDetails.personalDetails.panNumber.length > 0 &&
          activeTab !== 0
        ) {
          state.errorState.step1["pan"] = "Invalid PAN Number";
        } else {
          delete state.errorState.step1["pan"];
        }
      }
      if (field === "email") {
        if (
          !emailPattern.test(state.employeeDetails.personalDetails.email) &&
          activeTab !== 0
        ) {
          state.errorState.step1["personalEmail"] = "Invalid  Email";
        } else {
          delete state.errorState.step1["personalEmail"];
        }
      }
      if (field === "name") {
        if (
          !(/^[A-Za-z ]+$/.test(state.employeeDetails.name)) &&
          activeTab === 0
        ) {
          state.errorState.step0["name"] = "Invalid Name";
        } else {
          delete state.errorState.step0["name"];
        }
      }
      if (field === "emergencyContactName") {
        if (
          !(/^[A-Za-z ]+$/.test(state.employeeDetails.personalDetails.emergencyContactName)) &&
          state.employeeDetails.personalDetails.emergencyContactName.length > 0 &&
          activeTab === 1
        ) {
          state.errorState.step1["emergencyContactName"] = "Invalid Name";
        } else {
          delete state.errorState.step1["emergencyContactName"];
        }
      }
      if (field === "institute") {
        if (
          !(/^[A-Za-z ]+$/.test(state.employeeDetails.academicDetails.institute)) &&
          state.employeeDetails.academicDetails.institute.length > 0 &&
          activeTab === 2
        ) {
          state.errorState.step2["institute"] = "Invalid College Name";
        } else {
          delete state.errorState.step2["institute"];
        }
      }
      if (field === "bankName") {
        if (
          !(/^[A-Za-z ]+$/.test(state.employeeDetails.bankDetails.bankName)) &&
          state.employeeDetails.bankDetails.bankName.length > 0 &&
          activeTab === 4
        ) {
          state.errorState.step4["bankName"] = "Invalid Bank Name";
        } else {
          delete state.errorState.step4["bankName"];
        }
      }
      if (field === "branchName") {
        if (
          !(/^[a-zA-Z0-9\s]*$/.test(state.employeeDetails.bankDetails.branchName)) &&
          state.employeeDetails.bankDetails.branchName.length > 0 &&
          activeTab === 4
        ) {
          console.log("first","ajih")
          state.errorState.step4["branchName"] = "Invalid Branch Name";
        } else {
          delete state.errorState.step4["branchName"];
        }
      }
      if (field === "ifsccode") {
        if (
          !(/^[A-Za-z]{4}0[A-Z0-9]{6}$/.test(state.employeeDetails.bankDetails.ifsccode)) &&
          state.employeeDetails.bankDetails.ifsccode.length > 0 &&
          activeTab === 4
        ) {
          state.errorState.step4["ifsccode"] = "Invalid IFSC code";
        } else {
          delete state.errorState.step4["ifsccode"];
        }
      }
     
      if (field === "primaryContactNumber") {
        const validMobile = validateMobileNumber(
          state.employeeDetails.personalDetails.primaryContactNo
        );
        if (!validMobile) {
          state.errorState.step1["primaryContactNumber"] =
            "Invalid Mobile Number";
        } else {
          delete state.errorState.step1["primaryContactNumber"];
        }
      }
      if (field === "emergencyContactNumber") {
        const validMobile = validateMobileNumber(
          state.employeeDetails.personalDetails.emergencyContactNo
        );
        if (!validMobile) {
          state.errorState.step1["emergencyContactNumber"] =
            "Invalid Mobile Number";
        } else {
          delete state.errorState.step1["emergencyContactNumber"];
        }
      }
      // if (Object.keys(state.errorState).length > 0) {
      //   state.isErrorFields = true;
      // } else {
      //   state.isErrorFields = false;
      // }
    },
    updatePasswordSnackbar: (state, action) => {
      state.successOpen = action.payload;
    },
    updateEmployeeId: (state, action) => {
      state.employeeloginId = action.payload;
    },
    clearErrorState: (state, action) => {
      state.errorState = action.payload;
    },
    clearErrorStateWork: (state, action) => {
      state.errorStateWork = action.payload;
    },
    updateFeildsDisabled: (state, action) => {
      state.feildsDisabled = action.payload;
    }
  },
});

export const {
  updateAddEmployeeClicked,
  updateEmployeeJson,
  updateEmployeeDetails,
  updateEmployeeWorkExperience,
  addWorkExperience,
  clearEmployeeDetails,
  setIsUploadDocumentsDialogOpen,
  setUploadFileObj,
  updateEmployeeDocs,
  deleteOtherDocsFiles,
  updateErrorState,
  updateErrorStateWork,
  clearErrorState,
  clearErrorStateWork,
  updatePasswordSnackbar,
  updateEmployeeId,
  updateFeildsDisabled,
} = addEmployeeSlice.actions;

export default addEmployeeSlice.reducer;
