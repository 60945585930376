import S3 from "react-aws-s3";
import { isValidPhoneNumber } from 'react-phone-number-input';
import stagConfig from "../src/config/stagConfig.json"


export const makeid_alphanumeric = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < length; i++) {
    result += characters.charAt(array[i] % charactersLength);
  }

  return result;
};

export const upload_file_to_s3 = (
  path,
  file,
  filename,
  bucket = "cambri",
  unique = false
) => {
  const getFileExtension = (filename) => {
    const extension = filename.split(".").pop();
    return extension;
  };

  const s3config = {
    bucketName: bucket,
    dirName: path,
    region: "ap-south-1",
    accessKeyId: stagConfig.accessKeyId,
    secretAccessKey: stagConfig.secretAccessKey,
  };

  const ReactS3Client = new S3(s3config);
  const id = makeid_alphanumeric(6);
  const extn = getFileExtension(file.name);
  const newFileName = unique
    ? `${filename}_${id}.${extn}`
    : `${filename}.${extn}`;
  return ReactS3Client.uploadFile(file, newFileName);
};


export const validateMobileNumber =(number)=>{ 
  //if this function returns true, mobile number is valid or else invalid.
  if(!isValidPhoneNumber(number)){
      return false
  }
  else{
    return true
  }
}
