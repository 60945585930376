import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GeneralInformation from "./newLetterTemplate/GeneralInformation";
import UploadTemplate from "./newLetterTemplate/UploadTemplate";
import WorkflowSetup from "./newLetterTemplate/WorkflowSetup";
import { serviceUrl, genericHeaders } from "../../utils/apiConfig";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["General Information", "Upload Template", "Workflow Setup"];
}

function getStepContent(
  step,
  setFilePlaceholders,
  filePlaceholders,
  setFormData,
  formData
) {
  switch (step) {
    case 0:
      return (
        <GeneralInformation setFormData={setFormData} formData={formData} />
      );
    case 1:
      return (
        <UploadTemplate
          setFormData={setFormData}
          formData={formData}
          setFilePlaceholders={setFilePlaceholders}
        />
      );
    case 2:
      return (
        <WorkflowSetup
          filePlaceholders={filePlaceholders}
          setFormData={setFormData}
          formData={formData}
        />
      );
    default:
      return "Unknown step";
  }
}

function NewLetterTemplate({ setIsNewLetter }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [formData, setFormData] = useState({
    templateName: "",
    canBeRequestedByEmployee: "",
    customFields: [],
    document: {},
    isEnabled: null,
  });
  const [filePlaceholders, setFilePlaceholders] = useState();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const createLetter = async () => {
    try {
      const url = `${serviceUrl}/api/letterManagement/createLetter`;
      let headers = { ...genericHeaders() };
      const response = await axios.post(url, formData, {
        headers: headers,
      });
      setIsNewLetter(false);
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 1) {
      createLetter();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const { templateName, document } = formData;

  const isObjectEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== "") {
        return false;
      }
    }
    return true;
  };

  const disableNextButton = () => {
    if (templateName.trim() === "" && activeStep == 0 ) {
      return true;
    }
    if (isObjectEmpty(document) && activeStep == 1) {
      return true;
    }

    return false;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(
              activeStep,
              setFilePlaceholders,
              filePlaceholders,
              setFormData,
              formData
            )}
          </Typography>
          <div className="mt-10">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
              variant="outlined"
              color="primary"
            >
              Previous
            </Button>
            {isStepOptional(activeStep) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSkip}
                className={classes.button}
              >
                Cancel
              </Button>
            )}

            <Button
              disabled={disableNextButton()}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewLetterTemplate;
