import { Button, TextField, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useCreateLoginMutation} from "../api/loginApi";
import { clearCahce, getToken, setToken } from "../services/Token";
import "../css/snackbar.css";
import EiLogo from "../Icons/EiLogo.svg";
import HiIcon from "../Icons/handshake.svg";
import ICTLogo from "../Icons/LOGOICT.png";
import Loader from "../Components/customComponents/customLoader";
import { useLazyGetEmployeeListQuery } from "../api/addEmployeeApi";
import { useSelector, useDispatch } from "react-redux";
import eyeClose from "../Icons/eyeclose.svg";
import eyeOpen from "../Icons/eyeopen.svg";


const Login = () => {
  const [createLogin, { isSuccess, data, error }] = useCreateLoginMutation();
  const [getEmployeeList, empData] = useLazyGetEmployeeListQuery();
  const [loginData, setLoginData] = useState({
    employeeId: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState();
  const [iserror, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar
  const dispatch = useDispatch();
  const employeeLoginId = useSelector(
    (state) => state.addEmployee.employeeloginId
  );
  const history = useNavigate();
  const handleChange = (e) => {
    const newData = { ...loginData };
    if (e.target.name === "username") {
      let isValid = true;
      if (isValid) {
        newData.employeeId = e.target.value;
        setError("");
      } else {
        setError("Invalid User Name");
      }
    } else {
      newData.password = e.target.value;
    }
    setLoginData(newData);
  };
  const handleSubmit = async () => {
    setLoading(true);
    createLogin(loginData);
  };
  useEffect(() => {
    clearCahce();
    setLoginData((prev) => {
      return { ...prev, employeeId: employeeLoginId };
    });
  }, []);
  useEffect(() => {
    if (isSuccess) {
      setToken("Token", data.token);
      setToken("isEmailValidationEnabled", data.isEmailValidationEnabled)
      const decoded = jwtDecode(data.token);
      const roles = decoded.roles;
      setToken("roles", roles);
      const email = decoded.professionalEmail;
      setToken("professionalEmail", email);
      const Id = decoded.employeeId;
      setToken("Id", Id);
      const expiryTime = decoded.exp;
      let expiryTimestamp = expiryTime;
      let date = new Date(expiryTimestamp * 1000);
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");
      let seconds = String(date.getSeconds()).padStart(2, "0");
      let timeString = `${hours}:${minutes}:${seconds}`;
      setToken("expiryTime", timeString);
      localStorage.setItem("isLogin", data.isLogin);
      localStorage.setItem("loggedInToday",data?.sigindetails?.isloggedintoday);
      localStorage.setItem("lastLoggedInLocation",data?.sigindetails?.lastloggedinlocation);
      localStorage.setItem("lastLoggedInTime",data?.sigindetails?.lastloggedintime);
      if (roles[0] !== "SUPERADMIN") {
        getEmployeeList();
      }else if(roles[0] === "SUPERADMIN"){
        history("/superadmin")
      }
    } else if (error) {
      setLoading(false);
      const errorMessage = error.data?.message;
      setError(errorMessage);
      setSnackbarOpen(true);
    }
  }, [isSuccess, error]);
  useEffect(() => {
    if (empData.isSuccess) {
      const loggedInUserId = getToken("Id");
      const matchedEmployee = empData.data.find(
        (employee) => employee.id === loggedInUserId
      );
      if (matchedEmployee) {
        setToken("employeeName", matchedEmployee.name);
      }
      setLoading(false);
      history("/dashboard");
    } else if (empData.error) {
      setLoading(false);
      const errorMessage = empData.error.data?.message;
      setError(errorMessage);
      setSnackbarOpen(true);
    }
  }, [empData.isSuccess, empData.data,empData.isError]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isButtonDisabled = !(loginData.employeeId && loginData.password);
  return (
    <>
      {loading && <Loader />}
      <div className="h-screen flex flex-col items-center justify-center">
        <div className="relative">
          <img
            src={ICTLogo}
            alt="ICT Logo"
            className="w-16 h-16 sm:mr-54 md:w-32 md:h-32 lg:w-32 lg:h-32 lg:mb-24 xl:w-20 xl:h-20 2xl:mr-54"
          />
        </div>

        <form
          className="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 p-4 sm:p-6 shadow-lg bg-white rounded-lg space-y-4"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); 
              handleSubmit();
            }
          }}
          tabIndex="0"
        >
          <div className="text-center">
            <div className="flex items-center justify-center space-x-4 mb-4">
              <img src={EiLogo} alt="EI Logo" className="w-12 h-12" />
              <div className="text-center mb-[-2rem] mt-[-2rem]">
                <h2 className="text-3xl font-serif text-purple-800 mb-[0rem]">
                  <span className="font-extrabold">work</span><span className="font-extrabold">force</span><span className="text-4xl font-extrabold text-blue-600">HR</span>
                </h2>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-4 mt-[3rem]">
              <p className="text-lg text-black font-bold font-serif mr-[-0.75]">
                Hello There !!
              </p>
              <img src={HiIcon} alt="Hi Icon" className="w-8 h-8" />
            </div>
          </div>

          <div className="space-y-2">
            <p className="text-lg text-gray-600 font-mono font-semibold">
              User Name
            </p>
            <TextField
              name="username"
              onChange={handleChange}
              error={!!iserror}
              helperText={iserror}
              value={loginData.employeeId}
              fullWidth
              InputProps={{
                style: {
                  background: "#f3f3f3",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                },
              }}
            />
          </div>
          <div className="space-y-2">
            <p className="text-lg text-gray-600 font-mono font-semibold">
              Password
            </p>
            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              InputProps={{
                style: {
                  background: "#f3f3f3",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                },
                endAdornment: (
                  <img className="cursor-pointer"
                    src={showPassword ? eyeOpen : eyeClose}
                    onClick={(event) => {
                      event.preventDefault();
                      setShowPassword(!showPassword);
                      event.currentTarget.closest('form').focus();
                    }}
                  ></img>
                ),
              }}
            />
          </div>

          <div className="flex justify-end">
            <button className="text-blue-300 underline cursor-pointer" onClick={() => history("/reset")}>Forgot Password</button>
          </div>

          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isButtonDisabled}
            fullWidth
            style={{
              background: isButtonDisabled ? "grey" : "#007bff",
              color: isButtonDisabled ? "black" : "#fff",
              borderRadius: "10px",
            }}
          >
            Login
          </Button>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={
            <span style={{ color: "white", fontSize: "20px" }}>{iserror}</span>
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </div>
    </>
  );
};

export default Login;
