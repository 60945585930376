import React from "react";

function Select({ label, options, value, onChange, name, isMandatory,disabled, className }) {
  return (
    <div className="flex mb-4 items-center">
      <label className="block text-sm font-medium text-gray-700 mr-5">
        {label} {isMandatory && <span className="text-red-500 text-xl">*</span>}
      </label>
      <select
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        className={`border rounded-md py-2 px-3 w-60 ${className}`}
        disabled={disabled}
      >
        <option disabled={false} value="">-- Select an option --</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
