import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import PdfPreview from "./letterTemplates/pdfPreview";
import { Link } from "react-router-dom";

const PDFPreviewDialog = ({ open, handleClose, url }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle className="flex items-center justify-between">
          <p>Payslip Preview </p>
          <Link to={url} target="_blank" rel="noopener noreferrer">Download</Link>
        </DialogTitle>
        <DialogContent>
          <PdfPreview url={url} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{
              backgroundColor: "red",
              color: "white",
              border: "1px solid red",
              borderRadius: "10px",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PDFPreviewDialog;
