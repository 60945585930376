import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../CambriForm/PhoneInput.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { serviceUrl, genericHeaders } from "../utils/apiConfig";
import { Alert, Snackbar } from "@mui/material";
import Topbar from "./Topbar";

const Loader = () => (
  <div className="loader border-4 border-t-4 border-gray-200 border-t-blue-600 rounded-full w-6 h-6 animate-spin mx-auto"></div>
);

const CambriForm = () => {
  const navigate = useNavigate();
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [pageStatus, setPageStatus] = useState(true);
  const [snackOpen, setSnackOpen] = useState({
    open: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    elaboration: "",
    currentStudy: "",
    college: "",
    pursue: "",
    additionalProps: {},
    active: true,
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      if (value === "") {
        setEmailError("");
      } else if (emailPattern.test(value)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email");
      }
    }
  };

  const handleChangeMobileNumber = (value) => {
    setFormData({ ...formData, mobile: value });

    if (value === "" || value === "91") {
      setMobileError("");
    } else if (isValidPhoneNumber("+" + value)) {
      setMobileError("");
    } else {
      setMobileError("Please Enter Valid Mobile Number");
    }
  };

  const handleregistration = async () => {
    if (
      formData.email !== "" &&
      formData.mobile !== "" &&
      formData.name !== "" &&
      formData.pursue !== "" &&
      formData.currentStudy !== "" &&
      formData.college !== "" &&
      emailPattern.test(formData.email) &&
      isValidPhoneNumber("+" + formData.mobile)
    ) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${serviceUrl}/api/student/studentregistration`,
          formData,
          {
            headers: genericHeaders(),
          }
        );
        if (response.status === 200) {
          setRegistered(true);
          setFormData({
            name: "",
            email: "",
            mobile: "",
            elaboration: "",
            currentStudy: "",
            college: "",
            pursue: "",
            additionalProps: {},
            active: true,
          })
        //   setTimeout(() => {
        //     navigate("/");
        //   }, 2000);
        } else {
          setSnackOpen({ open: true, message: "Registration Failed" });
        }
      } catch (error) {
        setSnackOpen({ open: true, message: "Registration Failed" });
      } finally {
        setIsLoading(false);
      }
    } else {
      //   enqueueSnackbar("Please fill all fields", {
      //     variant: "error",
      //     anchorOrigin: {
      //       vertical: "top",
      //       horizontal: "center",
      //     },
      //     autoHideDuration: 2000,
      //   });
      setSnackOpen({ open: true, message: "Please fill all fields" });
    }
  };

  const getPageStatus = async() => {
    try{
      let url = `${serviceUrl}/api/student/registrationpagestatus`
      let response = await axios.get(url,genericHeaders())
      if(response.status === 200){
        setPageStatus(response.data.isactive);
      }else{
        setPageStatus(false);
      }
    }catch(err){
        setPageStatus(false);
        console.error(err);
    }
  }

  useEffect(() => {
     getPageStatus();
  },[])

  return (
    <>
      {pageStatus && !registered && (
        <>
          <Topbar/>
          <div className="flex flex-col md:px-14 px-4">
            <div className="text-center mt-16 text-2xl lg:text-2xl xl:text-3xl ">
              <span className="text-black font-bold">
                Please fill out the form below for registration
              </span>
            </div>
            <div className="border-2 border-[#A033FF] bg-center bg-cover rounded-[50px] my-[70px] py-10 px-5 md:px-28 mx-1 md:mx-32 ">
              <div className="flex justify-around gap-x-20 flex-wrap lg:flex-nowrap">
                <section className="w-full mt-14 flex flex-col gap-y-10">
                  <CustomTextField
                    label="Your Name"
                    value={formData.name}
                    handleChange={onChangeHandler}
                    name="name"
                    height="h-[60px]"
                  />
                  <div>
                    <CustomTextField
                      label="Email Address"
                      value={formData.email}
                      handleChange={onChangeHandler}
                      name="email"
                      height="h-[60px]"
                    />
                    <h1
                      className={`text-red-600 text-xs font-normal ${
                        emailError.trim() !== "" ? "block" : "hidden"
                      }`}
                    >
                      {emailError}
                    </h1>
                  </div>
                  <div className="">
                    <CustomTextField
                      label="Current College"
                      value={formData.college}
                      handleChange={onChangeHandler}
                      name="college"
                      height="h-[60px]"
                    />
                  </div>
                </section>

                <section className="w-full mt-14 flex flex-col gap-y-10">
                  <div>
                    <div className="text-base mb-2 text-black">
                      Mobile Number
                      <span className="text-red-600 text-base font-normal">
                        *
                      </span>
                    </div>
                    <div className="w-full">
                      <PhoneInput
                        containerClass="w-full"
                        inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
                        country={"in"}
                        value={formData.mobile}
                        onChange={handleChangeMobileNumber}
                      />
                      <h1
                        className={`text-red-600 text-xs font-normal ${
                          mobileError.trim() !== "" ? "block" : "hidden"
                        }`}
                      >
                        {mobileError}
                      </h1>
                    </div>
                  </div>

                  <div>
                    <div className="text-base mb-2 text-black">
                      Current Study
                      <span className="text-red-600 text-base font-normal">
                        *
                      </span>
                    </div>
                    <div className="w-full">
                      <select
                        value={formData.currentStudy}
                        onChange={onChangeHandler}
                        name="currentStudy"
                        className="mt-1 block w-full px-3 py-2 h-[60px] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option className="cursor-pointer" value="B.Com">
                          B.Com
                        </option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Tech">B.Tech</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <div className="text-base mb-2 text-black">
                      What you want to pursue
                      <span className="text-red-600 text-base font-normal">
                        *
                      </span>
                    </div>
                    <div className="w-full">
                      <select
                        value={formData.pursue}
                        onChange={onChangeHandler}
                        name="pursue"
                        className="mt-1 h-[60px] text block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="B.Com">B.Tech</option>
                        <option value="BSC">M.Tech</option>
                        <option value="BBA">MSC</option>
                        <option value="B.Tech">MBA</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </section>
              </div>
              <div className="w-full flex mt-6">
                <div className="flex justify-center w-full">
                  <CustomTextArea
                    label="Do you want to elaborate anything"
                    rows={2}
                    value={formData.elaboration}
                    handleChange={onChangeHandler}
                    name="elaboration"
                  />
                </div>
              </div>
              <div className="text-center mt-14">
                <button
                  className={`rounded-[40px] font-extrabold lg:text-[28px] text-black ${
                    formData.email === "" ||
                    formData.mobile === "" ||
                    formData.name === "" ||
                    !emailPattern.test(formData.email) ||
                    !isValidPhoneNumber("+" + formData.mobile) ||
                    formData.college === "" ||
                    formData.currentStudy === "" ||
                    formData.pursue === ""
                      ? "border border-[#A033FF] text-black cursor-not-allowed"
                      : "bg-blue-600 text-white cursor-pointer"
                  } py-2 px-4 w-full sm:w-[284px] h-10 lg:h-[64px] text-base leading-normal font-avenir mb-14`}
                  onClick={handleregistration}
                >
                  {isLoading ? <Loader /> : "Register"}
                </button>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={snackOpen.open}
              autoHideDuration={2000}
              onClose={() => setSnackOpen({ open: false, message: "" })}
            >
              <Alert
                severity={"error"}
                variant="filled"
                onClose={() => setSnackOpen({ open: false, message: "" })}
              >
                {snackOpen.message}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}

      {pageStatus && registered && (
        <div className="h-screen w-11/12 mx-auto flex justify-center items-center">
          <h2 className="text-2xl text-center py-5 px-3 border-2 border-[#A033FF] rounded-md">
            Thank you for registering! We look forward to serving you.
          </h2>
        </div>
      )}

      {!pageStatus && (
        <div className="h-screen w-11/12 mx-auto flex justify-center items-center">
          <h2 className="text-2xl text-center py-5 px-3 border-2 border-[#A033FF] rounded-md">
            Our page is temporarily unavailable. Please try again later. We
            appreciate your patience.
          </h2>
        </div>
      )}
    </>
  );
};

export default CambriForm;

export const CustomTextArea = (props) => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [error, setError] = useState("");
  const wordLimit = 230;

  const handleChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    if (words.length <= wordLimit) {
      setText(inputText);
      setWordCount(words.length);
      setError("");
      if (props.handleChange) {
        props.handleChange(event);
      }
    } else {
      setError(
        `Word limit of ${wordLimit} exceeded. Please shorten your text.`
      );
    }
  };

  return (
    <div className={``}>
      <label className={`block mb-2 mx-auto  text-xl font-semibold`}>
        {props.label}
      </label>
      <textarea
        {...props}
        value={text}
        className={`border border-[#DBDBDB] rounded-md ${
          props.label !== "Do you want to elaborate anything"
            ? "w-full"
            : "w-full md:w-[100%]"
        } px-4 py-5 bg-white text-[#4E5664]`}
        onChange={handleChange}
      />
      <div className="text-right mt-1 text-xs text-gray-500">
        {wordCount}/{wordLimit} words
      </div>
      {error && <div className="text-red-500 mt-1 text-sm">{error}</div>}
    </div>
  );
};

export const CustomTextField = (props) => {
  return (
    <div>
      <label className="block mb-2">
        {props.label}
        <span className="text-red-600 text-base font-normal">*</span>
      </label>
      <input
        value={props.value}
        name={props.name}
        type="text"
        className={`border border-[#DBDBDB] ${props.height} rounded-md w-full text-[#4E5664] text-base px-4 bg-white `}
        onChange={props.handleChange}
      />
    </div>
  );
};
