import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Tab, Tabs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { serviceUrl, genericHeaders } from "../../utils/apiConfig";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // borderBottom: "1px solid #dee2e6",
    // "& .MuiTabs-scroller": {
    //   overflow: "visible!important"
    // },
  },
  tab: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#0d6efd",
    background: "0 0",
    border: "1px solid transparent",
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
    padding: ".5rem 1rem",
    textDecoration: "none",
    transition:
      "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out",
    "&.Mui-selected": {
      color: "#495057",
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff",
      marginBottom: "-2px",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  },
}));

const LetterTemplateTab = ({ setIsNewLetter }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [allLetters, setAllLetters] = useState([]);

  const getAllLetters = async () => {
    try {
      const url = `${serviceUrl}/api/letterManagement/getAllLetterManagements`;
      let headers = { ...genericHeaders() };
      const response = await axios.get(url, {
        headers: headers,
      });
      const data = response.data.filter((item) => item.isActive === true);
      setAllLetters(data);
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  useEffect(() => {
    getAllLetters();
  }, []);

  const deleteLetter = async (id) => {
    try {
      const url = `${serviceUrl}/api/letterManagement/deleteLetterManagement?letterManagementId=${id}`;
      let headers = { ...genericHeaders() };
      const response = await axios.delete(url, {
        headers: headers,
      });
      if (response.status === 204) {
        getAllLetters();
      }
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = async (url) => {
    const fileUrl = url;

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          className={classes.root}
        >
          <Tab
            label="Letter Tempalte"
            {...a11yProps(0)}
            className={classes.tab}
          />
          {/* <Tab
            label="Template Gallery"
            {...a11yProps(1)}
            className={classes.tab}
          /> */}
        </Tabs>
        <div className="py-5">
          <TabPanel value={value} index={0}>
            <div className="flex justify-end">
              <Button
                style={{ border: "1px solid #3f51b5", padding: "5px 10px" }}
                color="primary"
                onClick={() => setIsNewLetter(true)}
              >
                New Letter Template
              </Button>
            </div>
            <div className="mt-5">
              {allLetters && allLetters.length > 0 ? (
                allLetters.map((item) => {
                  console.log(item,"lettere")
                  if (item.isActive) {
                    return (
                      <div
                        key={item._id}
                        className="flex justify-between border my-2 px-4 py-2 rounded-md bg-slate-50 border-zinc-400"
                      >
                        <p id={item._id} className="text-black">
                          {item.templateName}
                        </p>
                        <div className="flex items-center">
                          {/* <EditIcon /> */}
                          <svg
                            title="Download Letter Template"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => handleDownload(item.document.resourceName)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                          <DeleteRoundedIcon
                            title="Delete Letter Template"
                            className="cursor-pointer"
                            onClick={() => deleteLetter(item._id)}
                          />
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <p className="">No Letter Templates Found</p>
              )}
            </div>
            <div className="mt-10">{/* <LetterTemplateTab /> */}</div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            Activity
          </TabPanel> */}
        </div>
      </Box>
    </Box>
  );
};

export default LetterTemplateTab;
