import React from 'react'
import { Tab } from '@headlessui/react'
import ActiveLeaves from './ActiveLeaves';
import ClosedLeaves from './ClosedLeaves';
import PaySlip from './PaySlip';
import ReimbPaySlip from './ReimbPaySlip';

const PaySlipScreen = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl border-black border p-1 w-96 h-12">
          <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-blue-500 shadow text-white'
                    : 'hover:bg-blue-100 hover:text-blue-700'
                )
              }>PaySlip</Tab>
          <Tab className={({ selected }) =>
                classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-blue-500 shadow text-white'
                      : 'text-blue-700 hover:bg-blue-100 hover:text-blue-700'
                )
              }>Reimb.Payslip</Tab>
        </Tab.List>
        <Tab.Panels className="w-3/4 h-3/4  my-10 overflow-auto  scrollbar-thin">
          <Tab.Panel>{<PaySlip/>}</Tab.Panel>
          <Tab.Panel>{<ReimbPaySlip/>}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default PaySlipScreen