import React, { useEffect, useState } from "react";
import { Select, MenuItem, Alert, Snackbar } from "@mui/material";
import { useGetEmployeesListQuery } from "../api/getEmployeeList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectType,
  setEmployeeId,
  setDate,
  setSelectMonth,
  setPayDetails,
  setError,
  setPayload,
  setAdditionalFields,
  setEmployeeType,
  setResponseData,
  setOptions,
  setDatesSelection,
  addField,
  updateField,
  setPayrollId,
  setDisablePayroll,
} from "../store/reducers/payrollSlice";
import PayrollLoader from "../Components/customComponents/customLoaderPayroll";

function Payroll() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const selectType = useSelector((state) => state.payroll.selectType);
  const selectMonth = useSelector((state) => state.payroll.selectMonth);
  const additionalFields = useSelector(
    (state) => state.payroll.additionalFields
  );
  const payDetails = useSelector((state) => state.payroll.payDetails);
  const employeeType = useSelector((state) => state.payroll.employeeType);
  const error = useSelector((state) => state.payroll.error);
  const payload = useSelector((state) => state.payroll.payload);
  const getPayload = useSelector((state) => state.payroll.getPayload);
  const navigation = useSelector((state) => state.payroll.navigation);
  const selectDates = useSelector((state) => state.payroll.datesSelection);
  const payrollId = useSelector((state) => state.payroll.payrollId);
  const disablePayroll = useSelector((state) => state.payroll.disablePayroll);
  const [loading, setLoading] = useState(false);
  const [employeeLoader, setEmployeeLoader] = useState(false);

  const handleSelectChange = async (date) => {
    dispatch(setSelectMonth(date));

    const employee = selectType;
    if (date && selectType) {
      dispatch(setError(""));
    }
    if (employee && date) {
      await fetchPayrollData(employee.id, date);
    }
  };
  const fetchPayrollData = async (employeeId, dateTime) => {
    dispatch(setDate(dateTime));
    try {
      setLoading(true);

      let response = await axios.post(
        `${serviceUrl}/api/payrollmanagement/fetchpayrollbyempidanddate`,
        { ...getPayload, employeeId, dateTime: dateTime },
        { headers: genericHeaders() }
      );

      dispatch(setPayrollId(response?.data?._id));
      if (response.status === 200) {
        setLoading(false);
      }

      let payDetailsModifed = payDetails.map((item) => {
        let newItem = { ...item };
        switch (newItem.id) {
          case 1:
            newItem["totalNetPay"] = response?.data?.totalNetPay;
            break;
          case 2:
            newItem["totalGrossPay"] = response?.data?.totalGrossPay;
            break;
          case 3:
            newItem["totalDeductionPay"] = response?.data?.totalDeduction;
            break;
          default:
            break;
        }
        return newItem;
      });

      if (response?.data?.totalDeductionDetails) {
        let totalDeductionPayDetals = response?.data?.totalDeductionDetails;

        let deductionFields = totalDeductionPayDetals.map((item) => ({
          displayText: item.displayText,
          value: item.value,
        }));

        dispatch(
          setAdditionalFields({ key: "DEDUCTION", value: deductionFields })
        );
      }

      if (response?.data?.totalGrossPayDetails) {
        let totalGrossPayDetals = response?.data?.totalGrossPayDetails;
        let grossPayFields = totalGrossPayDetals.map((item) => ({
          displayText: item.displayText,
          value: item.value,
        }));
        dispatch(
          setAdditionalFields({ key: "GROSS PAY", value: grossPayFields })
        );
      }

      dispatch(setResponseData(response.data));
      dispatch(setPayDetails(payDetailsModifed));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const { data: employeeData, isSuccess: employeesIsSuccess } =
    useGetEmployeesListQuery();

  const handleChange = (event) => {
    dispatch(setEmployeeType(event.target.value));
  };

  useEffect(() => {
    if (employeesIsSuccess) {
      setEmployeeLoader(true);
    } else {
      setEmployeeLoader(false);
    }
  }, [employeesIsSuccess]);

  const handleEmployeeList = async (event) => {
    dispatch(setSelectMonth(""));
    const employeeListId = event.target.value;
    const employee = employeeData.find((emp) => emp.id === employeeListId);
    dispatch(setSelectType(employee));
    const dateTime = selectMonth !== "" ? selectMonth : null;
    let responseOfDates = await axios.get(
      `${serviceUrl}/api/payrollmanagement/fetchdatesforpayroll?employeeId=${employeeListId}`,
      { headers: genericHeaders() }
    );
    if (responseOfDates.status === 200) {
      dispatch(setDatesSelection(responseOfDates.data));
    }
    if (employee && dateTime) {
      dispatch(setError(""));
      await fetchPayrollData(employee.id, dateTime);
    }
  };

  useEffect(() => {
    dispatch(
      setPayload({
        totalGrossPayDetails: additionalFields["GROSS PAY"] || [],
        totalDeductionDetails: additionalFields["DEDUCTION"] || [],
      })
    );
  }, [additionalFields]);

  useEffect(() => {
    let totalNetPayPayload = 0;
    let totalGrossPayPayload = 0;
    let totalDeductionPayload = 0;
    payDetails.forEach((item) => {
      if (item.id === 1) {
        totalNetPayPayload = item.totalNetPay;
      }
      if (item.id === 2) {
        totalGrossPayPayload = item.totalGrossPay;
      }
      if (item.id === 3) {
        totalDeductionPayload = item.totalDeductionPay;
      }
    });
    dispatch(
      setPayload({
        ...payload,
        totalNetPay: totalNetPayPayload,
        totalGrossPay: totalGrossPayPayload,
        totalDeduction: totalDeductionPayload,
      })
    );
  }, [payDetails]);
  const handleAddField = (item) => {
    dispatch(addField({ item }));
  };

  useEffect(() => {
    if (!navigation) {
      dispatch(setSelectMonth(""));
      dispatch(setSelectType(""))
    }
  }, []);

  const handlePayChange = (e, id) => {
    let payAmount = 0;
    if (/^\d+$/.test(e.target.value) || e.target.value === "") {
      payAmount = e.target.value;
    }
    const payDetailsChange = payDetails.map((item) => {
      let newPayDetails = { ...item };
      switch (id) {
        case 1:
          newPayDetails["totalNetPay"] = payAmount;
          break;
        case 2:
          newPayDetails["totalGrossPay"] = payAmount;
          break;
        case 3:
          newPayDetails["totalDeductionPay"] = payAmount;
          break;
        default:
          break;
      }
      return newPayDetails;
    });
    dispatch(setPayDetails(payDetailsChange));
  };
  useEffect(() => {}, [additionalFields]);

  const handleInputChange = (fieldName, index, value, type) => {
    dispatch(updateField({ fieldName, index, value, type }));
    const updatedAdditionalFields = additionalFields[fieldName].map(
      (field, idx) => {
        if (idx === index) {
          if (type === "value") {
            if (/^\d+$/.test(value) || value === "") {
              return {
                ...field,
                value: value,
              };
            }
          }
          if (type === "displayText") {
            return {
              ...field,
              displayText: value,
            };
          }
        }
        return field;
      }
    );

    let totalAdditionalFieldValue = 0;
    updatedAdditionalFields.forEach((field) => {
      if (fieldName === "GROSS PAY" && field.value !== "") {
        const parsedValue = parseInt(field.value);
        if (!isNaN(parsedValue)) {
          totalAdditionalFieldValue += parsedValue;
        }
      }
      if (fieldName === "DEDUCTION" && field.value !== "") {
        const parsedValue = parseInt(field.value);
        if (!isNaN(parsedValue)) {
          totalAdditionalFieldValue += parsedValue;
        }
      }
    });

    let updatedPayDetails = payDetails.map((item) => {
      let updatedItem = { ...item };
      if (fieldName === "GROSS PAY" && item.id === 2) {
        updatedItem.totalGrossPay = totalAdditionalFieldValue;
      }
      if (fieldName === "DEDUCTION" && item.id === 3) {
        updatedItem.totalDeductionPay = totalAdditionalFieldValue;
      }
      return updatedItem;
    });
    dispatch(
      setAdditionalFields({
        key: fieldName,
        value: updatedAdditionalFields,
        displayText: value,
      })
    );
    dispatch(setPayDetails(updatedPayDetails));
  };

  const handleProcessPayroll = async () => {
    try {
      let totalNetPay = 0;
      let totalDeduction = 0;
      let totalGrossPay = 0;
      payDetails.forEach((item) => {
        if (item.id === 1) {
          totalNetPay = item.totalNetPay;
        }
        if (item.id === 2) {
          totalGrossPay = item.totalGrossPay;
        }
        if (item.id === 3) {
          totalDeduction = item.totalDeductionPay;
        }
      });
      if (totalNetPay > totalGrossPay || totalDeduction > totalGrossPay) {
        dispatch(
          setError("Net or Deduction value cannot be greater than Gross Pay")
        );
        return;
      }
      if (!selectType || !selectMonth) {
        dispatch(setError("Please Select the employee and month"));
        return;
      } else if (
        totalGrossPay == 0 ||
        totalNetPay == 0 ||
        totalDeduction == 0
      ) {
        dispatch(setError("Please enter all the fields"));
        return;
      }
      const fieldsHaveText = Object.values(additionalFields)
        .filter((fields) => fields !== undefined) 
        .every((fields) =>
          fields.every(
            (field) => field.displayText && field.displayText.trim() !== ""
          )
        );

      if (!fieldsHaveText) {
        dispatch(setError("Please ensure all fields have display text"));
        return;
      }

      if (
        totalNetPay < totalGrossPay ||
        totalDeduction < totalGrossPay ||
        selectType ||
        selectMonth
      ) {
        setLoading(true);
        dispatch(setError(""));
        let response = await axios.post(
          `${serviceUrl}/api/payrollmanagement/createpayroll`,
          {
            ...payload,
            employeeId: selectType.id,
            payRollProcessedDate: selectMonth,
            _id: payrollId,
          },
          { headers: genericHeaders() }
        );
        if (response.status === 201) {
          dispatch(setDisablePayroll(true));
          dispatch(setPayrollId(response?.data?._id));
          setLoading(false);
          setSnackBarMessage("Payroll updated successfully!");
          setSnackOpen(true);
        }

        let payDetailsModifed = payDetails.map((item) => {
          let newItem = { ...item };
          switch (newItem.id) {
            case 1:
              newItem["totalNetPay"] = response?.data?.totalNetPay;
              break;
            case 2:
              newItem["totalGrossPay"] = response?.data?.totalGrossPay;
              break;
            case 3:
              newItem["totalDeductionPay"] = response?.data?.totalDeduction;
              break;
            default:
              break;
          }
          return newItem;
        });
        if (response?.data?.totalDeductionDetails) {
          let totalDeductionPayDetals = response?.data?.totalDeductionDetails;
          let deductionFields = totalDeductionPayDetals.map((item) => ({
            displayText: item.displayText,
            value: item.value,
          }));
          dispatch(
            setAdditionalFields({ key: "DEDUCTION", value: deductionFields })
          );
        }

        if (response?.data?.totalGrossPayDetails) {
          let totalGrossPayDetals = response?.data?.totalGrossPayDetails;
          let grossPayFields = totalGrossPayDetals.map((item) => ({
            displayText: item.displayText,
            value: item.value,
          }));
          dispatch(
            setAdditionalFields({ key: "GROSS PAY", value: grossPayFields })
          );
        }

        dispatch(setResponseData(response.data));
        dispatch(setPayDetails(payDetailsModifed));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleProcessPayrollClick = () => {
    let totalNetPay = 0;
    let totalDeduction = 0;
    let totalGrossPay = 0;
    payDetails.forEach((item) => {
      if (item.id === 1) {
        totalNetPay = item.totalNetPay;
      }
      if (item.id === 2) {
        totalGrossPay = item.totalGrossPay;
      }
      if (item.id === 3) {
        totalDeduction = item.totalDeductionPay;
      }
    });
    if (!selectType) {
      dispatch(setError("Please Select the employee"));
      return;
    } else if (!selectMonth) {
      dispatch(setError("Please Select the month"));
      return;
    } else if (totalGrossPay == 0 || totalNetPay == 0 || totalDeduction == 0) {
      dispatch(setError("Please enter all the fields"));
      return;
    } else if (totalNetPay > totalGrossPay || totalDeduction > totalGrossPay) {
      dispatch(
        setError("Net or Deduction value cannot be greater than Gross Pay")
      );
      return;
    }

    if (selectType && selectMonth) {
      setError("");
      dispatch(setEmployeeId(selectType.id));
      navigate("/payrollProcessing");
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const maxDate = dayjs();
  const disabledDates = selectDates.map((dateStr) => {
    const [month, year] = dateStr.split("-").map(Number);
    return {
      month: month - 1,
      year,
    };
  });

  const shouldDisableMonth = (month) => {
    return disabledDates.some(
      (date) => date.month === month.month() && date.year === month.year()
    );
  };

  return (
    <>
      {!employeeLoader && (
        <PayrollLoader text="Loading Data . Please Wait..." />
      )}

      <div className="flex flex-col w-full overflow-x-auto">
        <div className="flex flex-col border border-gray-300 rounded-2xl bg-white w-full ml-4 mt-8 shadow-lg">
          <div className="flex flex-row justify-between p-6">
            <div className="flex flex-col">
              <h1 className="text-gray-800 font-bold text-lg">
                Employee Type:
              </h1>
              <Select
                id="employeeType"
                name="employeeType"
                value={employeeType}
                onChange={handleChange}
                className="mt-2 w-64 rounded-lg border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
              >
                <MenuItem value="currentEmployee">Current Employee</MenuItem>
                {/* <MenuItem value="resignedEmployee">Resigned Employee</MenuItem> */}
              </Select>
            </div>
            {employeeType === "currentEmployee" && employeesIsSuccess && (
              <div className="flex flex-col">
                <h1 className="text-gray-800 font-bold text-lg">
                  Employee List:
                </h1>
                <Select
                  id="employeeList"
                  name="employeeList"
                  value={selectType ? selectType.id : "Select Type"}
                  onChange={handleEmployeeList}
                  className="mt-2 w-64 rounded-lg border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                >
                  {employeeData.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex flex-col">
              <h1 className="text-gray-800 font-bold text-lg">Select Month:</h1>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  maxDate={maxDate}
                  value={selectMonth ? dayjs(selectMonth) : null}
                  openTo="month"
                  views={["month", "year"]}
                  onChange={(date) => {
                    handleSelectChange(date);
                  }}
                  shouldDisableMonth={shouldDisableMonth}
                  sx={{
                    maxHeight: 30,
                    width: "15rem",
                    bgcolor: "white",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "1.25rem",
                    "& .MuiInputBase-root": {
                      height: 30,

                    },
                    "& .MuiPickersMonth-monthButton": {
                      backgroundColor:`${shouldDisableMonth ? "transparent" : "blue"}`,
                      color: `${shouldDisableMonth ? "none" : "white"}`
                    },
                    "& .MuiPickersMonth-monthButton.Mui-selected": {
                      backgroundColor: `${shouldDisableMonth ? "transparent" : "blue"}`,
                      color: `${shouldDisableMonth ? "#00000099" : "white"}`
                    },
                    borderRadius: "0.375rem",
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex flex-col justify-end space-y-2">
              <button
                className="text-white bg-blue-600 border border-blue-600 hover:bg-blue-700 rounded-lg py-2 px-6 font-bold"
                onClick={() => {
                  handleProcessPayroll();
                }}
              >
                Update Salary
              </button>
              <button
                className={`${
                  !disablePayroll
                    ? "text-black bg-gray-300 border border-gray-300 hover:bg-gray-300 "
                    : "text-white bg-blue-600 border border-blue-600 hover:bg-blue-700"
                }  rounded-lg py-2 px-6 font-bold`}
                onClick={() => {
                  handleProcessPayrollClick();
                }}
                disabled={!disablePayroll}
              >
                Process Payroll
              </button>
              {error && <p className="text-red-500 font-medium">{error}</p>}
            </div>
          </div>
        </div>

        {loading && (
          <PayrollLoader text="Data is submitting . Please Wait..." />
        )}
        {selectType?.id && selectMonth && (
          <div className="my-4">
            <div className="bg-white ">
              {selectType?.id && (
                <div className="flex flex-col justify-between p-4">
                  {payDetails.map((item, index) => (
                    <div key={item.id} className="flex flex-col">
                      <div className="flex flex-row justify-between border border-b-gray-300 p-4">
                        <div className="flex gap-1">
                          <h1 className="text-gray-700 font-bold">
                            {item.name}
                          </h1>
                          {item.name !== "NET PAY" && (
                            <span>
                              <button
                                type="button"
                                onClick={() => handleAddField(item.name)}
                                className="cursor-pointer"
                              >
                                <AddCircleIcon />
                              </button>
                            </span>
                          )}
                        </div>
                        <input
                          className="border border-gray-200 rounded-xl p-2"
                          type="text"
                          disabled={!!(item.id === 2 || item.id === 3)}
                          value={(() => {
                            switch (item.id) {
                              case 1:
                                return item.totalNetPay;
                              case 2:
                                return item.totalGrossPay;
                              case 3:
                                return item.totalDeductionPay;
                              default:
                                return null; // Or some other default value if needed
                            }
                          })()}
                          onChange={(e) => handlePayChange(e, item.id)}
                        />
                      </div>
                      {additionalFields[item.name] &&
                        additionalFields[item.name].length > 0 &&
                        additionalFields[item.name].map((field, index) => (
                          <div
                            key={item.id}
                            className="flex flex-row justify-between border border-b-gray-300 p-4"
                          >
                            <div>
                              <input
                                className="border border-gray-200 rounded-xl p-2"
                                type="text"
                                value={field.displayText}
                                onChange={(e) =>
                                  handleInputChange(
                                    item.name,
                                    index,
                                    e.target.value,
                                    "displayText"
                                  )
                                }
                              />
                            </div>
                            <div>
                              <input
                                className="border border-gray-200 rounded-xl p-2"
                                type="text"
                                value={field.value.trim()}
                                onChange={(e) =>
                                  handleInputChange(
                                    item.name,
                                    index,
                                    e.target.value,
                                    "value"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
        >
          <Alert
            severity="success"
            style={{ backgroundColor: "green", color: "white" }}
            onClose={handleSnackClose}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Payroll;
