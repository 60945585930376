import React, { useState } from "react";
import TextField from "../../Components/customComponents/customTextField";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {
  updateEmployeeWorkExperience,
  addWorkExperience,
  updateEmployeeDetails
} from "../../store/reducers/addEmployeeSlice";
import { upload_file_to_s3 } from '../../utils';
import { DocumentIcon,PlusCircleIcon } from "@heroicons/react/24/solid"
import { faL } from "@fortawesome/free-solid-svg-icons";

const Experience = () => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const feildDisabled = useSelector((state) => state.addEmployee.feildsDisabled);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [docName,setDocName] = useState();
  const [docFormat,setDocFormat] = useState();
  const [index,setIndex] = useState();
  
  const handleChange = (i, e) => {
    if (e.target.name == "startDate" || e.target.name == "endDate") {
      dispatch(
        updateEmployeeWorkExperience({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
          index: i,
        })
      );
    } else {
      dispatch(
        updateEmployeeWorkExperience({
          value: e.target.value,
          path: e.target.name,
          index: i,
        })
      );
    }
  };
  const handleDocsChange = async (evt) =>{
    const file_to_upload = evt.target.files[0];
    let public_url;
    
    try {
      const s3path = await upload_file_to_s3(
        "cv",
        // new_file.target.files[0],
        file_to_upload,
        `u_${new Date().getTime()}_cv`
      );
      public_url = s3path.location;
      
    } catch (err) {
      console.error(err);
    }
    let document = {
      fileName: file_to_upload.name, // You can set the name as needed
      name: docName.trim(),
      resourceName: public_url, // You can set the file url as needed
      uploadDate: new Date().toISOString(),
      comment: "",
      extension: file_to_upload.name.split(".").pop(),
    };
    setDocFormat(document);
  }
  const handleDocNameChange = (evt) => {
    setDocName(evt.target.value);
  }
  const handleOpen = (i) => {
    setOpen(true);
    setIndex(i);
  }
  const handleClose = () => {
    setOpen(false);
    setDocName()
    setIndex();
  }
  const handleSave = () => {
    if (docFormat) {
      let newData = [...empState.workExperience[index].documents];
      newData.push(docFormat);
      dispatch(
        updateEmployeeWorkExperience({
          value: newData,
          path: "documents",
          index: index,
        })
      );
      setDocFormat();
      handleClose();
    }
  };
  const handleDocDelete = (workIndex,docIndex) => {
    let newData = [...empState.workExperience[workIndex].documents];
    newData.splice(docIndex,1)
    dispatch(
      updateEmployeeWorkExperience({
        value: newData,
        path: "documents",
        index: workIndex,
      })
    )
  }
  const handleDeleteWorkExperience = (workIndex) => {
    let newData = [...empState.workExperience];
    newData.splice(workIndex,1);
    dispatch(
      updateEmployeeDetails({
        value: newData,
        path: "employeeDetails.workExperience",
      })
    )
  }
  const addWorkExperienceHandler = () => {
    dispatch(addWorkExperience());
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload documents "}</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="File Name"
            type="text"
            value={docName}
            onChange={handleDocNameChange}
            // isMandatory={true}
          />
          <TextField
            name="file"
            label="Upload file"
            type="file"
            // value={empState.name}
            onChange={handleDocsChange}
            // isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
      <div className="flex">
        <h1 className="text-2xl mb-10">Step 4: Work Experience</h1>
        {feildDisabled ? <></> :(
        <PlusCircleIcon
          onClick={addWorkExperienceHandler}
          className="text-2xl w-9 h-9 mx-2 cursor-pointer"
        />) }
      </div>
      <div className="flex flex-wrap gap-4 space-y-2 my-5">
        {empState.workExperience.map((item, i) => (
          <>
            <div
              key={i}
              className="bg-blue-100 rounded-lg flex flex-col shadow-md h-[22rem] w-4/5 mx-auto relative"
            >
              {feildDisabled ? (
                <></>
              ) : (
                <div
                  className={`h-6 w-6 my-auto text-red-500 cursor-pointer absolute right-6 top-6 ${
                    i === 0 ? "hidden" : ""
                  }`}
                  onClick={() => handleDeleteWorkExperience(i)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              )}
              <div className="flex py-10 h-[23rem]">
                <section className="w-1/2 flex flex-col justify-center items-end">
                  <TextField
                    name="companyName"
                    label="Company Name"
                    type="text"
                    onChange={(e) => handleChange(i, e)}
                    value={item.companyName}
                    disabled={feildDisabled}
                  />
                  <TextField
                    name="designation"
                    label="Designation"
                    type="text"
                    onChange={(e) => handleChange(i, e)}
                    value={item.designation}
                    disabled={feildDisabled}
                  />
                  <TextField
                    name="startDate"
                    label="From Date"
                    type="date"
                    onChange={(e) => handleChange(i, e)}
                    value={item.startDate ? item.startDate.split("T")[0] : ""}
                    max={item.endDate ? (() => {
                      const toDate = new Date(item.endDate);
                      toDate.setDate(toDate.getDate() - 1);
                      return toDate.toISOString().split("T")[0];
                    })() : new Date().toISOString().split("T")[0]}
                    disabled={feildDisabled}
                  />
                  <TextField
                    name="endDate"
                    label="to Date"
                    type="date"
                    onChange={(e) => handleChange(i, e)}
                    value={item.endDate ? item.endDate.split("T")[0] : ""}
                    min={item.startDate ? (() => {
                      const fromDate = new Date(item.startDate);
                      fromDate.setDate(fromDate.getDate() + 1);
                      return fromDate.toISOString().split("T")[0];
                    })() :""}
                    max={new Date().toISOString().split("T")[0]}
                    disabled={feildDisabled}
                  />
                  {feildDisabled ? (
                    <></>
                  ) : (
                    <div
                      className="bg-blue-100 border border-blue-400 text-blue-700 px-7 py-1 rounded relative mb-5 cursor-pointer"
                      role="alert"
                      onClick={() => handleOpen(i)}
                    >
                      <strong className="font-bold text-xl">
                        Upload Documents
                      </strong>
                      {/* <p className="font-bold text-xl text-center">+</p> */}
                    </div>
                  )}
                </section>
                <section className="mx-auto overflow-y-auto scrollbar-thin space-y-3">
                  {empState.workExperience[i].documents.map((item, index) => {
                    return (
                      <>
                        <div className="bg-blue-50 rounded-lg p-2 shadow-md w-full flex justify-between ">
                          <div className="flex">
                            <div
                              className="text-blue-800 my-auto"
                              title="Document Name That Is Very Long and Doesn't Fit in the Card"
                            >
                              <DocumentIcon className="w-7 h-7" />
                            </div>
                            <div class="text-blue-500 mx-2 w-48">
                              <p className="text-lg">
                                {item.name.length > 20
                                  ? item.name.substring(0, 20) + "..."
                                  : item.name}
                              </p>
                              <p className="text-xs">
                                {item.fileName.length > 27
                                  ? item.fileName.substring(0, 27) + "..."
                                  : item.fileName}
                              </p>
                            </div>
                          </div>
                          {feildDisabled ? (
                            <></>
                          ) : (
                            <div
                              className="h-6 w-6 my-auto text-red-500 cursor-pointer"
                              onClick={() => handleDocDelete(i, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </section>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Experience;