import React from "react";
import { getToken } from "../../services/Token";
import dayjs from "dayjs";
import { useCreateProfileQuery } from "../../api/profileApi";

function EmployeeInformation() {
  const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });

  return (
<div className="w-full">
<div className="w-[95%] bg-white rounded-xl mx-auto">
              <div className="p-3">
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Employee Information
                </h3>
                <div className="grid grid-cols-4 p-2 gap-2">
                  <div>
                    <h4 className="text-slate-600">Title</h4>
                    <p className="my-1 text-lg">
                      {data?.personalDetails?.title
                        ? data?.personalDetails?.title
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Name</h4>
                    <p className="my-1 text-lg">
                      {data?.name ? data?.name : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Gender</h4>
                    <p className="my-1 text-lg">
                      {" "}
                      {data?.personalDetails?.gender
                        ? data?.personalDetails?.gender
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Mobile</h4>
                    <p className="my-1 text-lg">
                      {" "}
                      {data?.personalDetails?.primaryContactNo
                        ? data?.personalDetails?.primaryContactNo
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Email</h4>
                    <p className="my-1 text-lg">
                      {" "}
                      {data?.professionalDetails?.companyEmail
                        ? data?.professionalDetails?.companyEmail
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
    </div>
  );
}
export default EmployeeInformation;
