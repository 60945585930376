import React, {useState} from "react";
import { getToken } from "../../services/Token";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { useCreateProfileQuery } from "../../api/profileApi";
import EditIcon from "@mui/icons-material/Edit";
import { CustomPhoneInput } from "../EmployeeDetails";

function PresentAddress(props) {
  // const Id = getToken("Id");
  // const { data, isSuccess } = useCreateProfileQuery({
  //   employeeId: `${Id}`,
  // });

  return (
    <div className="w-full">
      <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
        <div>
          <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
            Present Address
            <span className={`mx-2 cursor-pointer ${
                      props.edit.presentAddress ? "hidden" : ""
                    }`}
                    onClick={() =>
                      props.setEdit((prevEdit) => ({
                        ...prevEdit,
                        presentAddress : true,
                      }))
                    }
                  >
                    {<EditIcon />}
            </span>
          </h3>

          {
            props.edit.presentAddress ? 
              <EditPresentAddress
                data={props.data}
                setEmployee={props.setEmployee}
                edit={props.edit}
                setEdit={props.setEdit}
                save={props.save}
                oldData={props.oldData}
             /> : 
              <>
                <div className="grid grid-cols-4 p-2 gap-2">
                  <div>
                    <h4 className="text-slate-600">Name</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.name
                        ? props.data?.currentAddress?.name
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Address</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.address
                        ? props.data?.currentAddress?.address
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">City</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.city
                        ? props.data?.currentAddress?.city
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">State</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.state
                        ? props.data?.currentAddress?.state
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Country</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.country
                        ? props.data?.currentAddress?.country
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Pincode</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.pincode
                        ? props.data?.currentAddress?.pincode
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Phone</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.phone
                        ? props.data?.currentAddress?.phone
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Fax</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.fax
                        ? props.data?.currentAddress?.fax
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Mobile Number</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.mobileNumber
                        ? props.data?.currentAddress?.mobileNumber
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Email</h4>
                    <p className="my-1 text-lg">
                      {props.data?.currentAddress?.email
                        ? props.data?.currentAddress?.email
                        : "-"}
                    </p>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
}
export default PresentAddress;

export const EditPresentAddress = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  let regex = /^[a-zA-Z0-9\s]*$/
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError((prevError) => ({ ...prevError, email : "Enter valid email" }));
      }else{
        setError((prevError) => ({ ...prevError, email : "" }));
      }
      newData.currentAddress.email = e.target.value;
    }else{
      newData.currentAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      setError((prevError) => ({ ...prevError, phone: "Invalid phone number" }));
    }else{
      setError((prevError) => ({ ...prevError, phone: "" }));
    }
    newData.currentAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.currentAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.currentAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.currentAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.currentAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.currentAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.currentAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.currentAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.currentAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
            type="text"
            name="mobileNumber"
            value={formData?.currentAddress?.mobileNumber || ""}
            className='border rounded-md my-2 px-2 py-1'
            onChange={handleInputChange}
          /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.currentAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Phone"
           />
         </div>
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.currentAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             presentAddress: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}