import React, {useState} from "react";
import { cloneDeep } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { CustomPhoneInput } from "../EmployeeDetails";

function EmergencyContact(props) {
  // const Id = getToken("Id");
  // const { data, isSuccess } = useCreateProfileQuery({
  //   employeeId: `${Id}`,
  // });
  return (
    <div className="w-full">
                  <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Emergency Contact
                  <span className={`mx-2 cursor-pointer ${props.edit.emergencyContact ? "hidden" : ""
                    }`}
                    onClick={() =>
                      props.setEdit((prevEdit) => ({
                        ...prevEdit,
                        emergencyContact: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </span>
                </h3>
                { props.edit.emergencyContact ?
                    <EditEmergencyContact
                      data={props.data}
                      setEmployee={props.setEmployee}
                      edit={props.edit}
                      setEdit={props.setEdit}
                      save={props.save}
                      oldData={props.oldData}
                    /> :
                    <>
                    <div className="grid grid-cols-4 p-2 gap-2">
                      <div>
                        <h4 className="text-slate-600">Name</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.name
                            ? props.data?.emergencyContactAddress?.name
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Relationship</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.relationship
                            ? props.data?.emergencyContactAddress?.relationship
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Address</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.address
                            ? props.data?.emergencyContactAddress?.address
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">City</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.city
                            ? props.data?.emergencyContactAddress?.city
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">State</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.state
                            ? props.data?.emergencyContactAddress?.state
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Country</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.country
                            ? props.data?.emergencyContactAddress?.country
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Pincode</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.pincode
                            ? props.data?.emergencyContactAddress?.pincode
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Phone</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.phone
                            ? props.data?.emergencyContactAddress?.phone
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Fax</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.fax
                            ? props.data?.emergencyContactAddress?.fax
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Mobile Number</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.mobileNumber
                            ? props.data?.emergencyContactAddress?.mobileNumber
                            : "-"}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-slate-600">Email</h4>
                        <p className="my-1 text-lg">
                          {props.data?.emergencyContactAddress?.email
                            ? props.data?.emergencyContactAddress?.email
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
    </div>
  )
}
export default EmergencyContact;

export const EditEmergencyContact = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let errorData = {...error}
      let isValid = emailRegex.test(email);
      if(isValid === false){
        errorData.email = "Invalid Email"
      }else{
        errorData.email = ""
      }
      setError(errorData);
      newData.emergencyContactAddress.email = e.target.value;
    }else{
    newData.emergencyContactAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    let errorData = {...error}
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      errorData.phone = "Invalid phone number";
    }else{
      errorData.phone = "";
    }
    setError(errorData);
    newData.emergencyContactAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.emergencyContactAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Relationship</h4>
         <input
           type="text"
           name="relationship"
           value={formData?.emergencyContactAddress?.relationship || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.emergencyContactAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.emergencyContactAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.emergencyContactAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.emergencyContactAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.emergencyContactAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.emergencyContactAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.emergencyContactAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
            type="text"
            name="mobileNumber"
            value={formData?.emergencyContactAddress?.mobileNumber || ""}
            className='border rounded-md my-2 px-2 py-1'
            onChange={handleInputChange}
          /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.emergencyContactAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Mobile Number"
           />
         </div>
         {error.phone !== "" && (
           <p className="text-red-500 text-xs">{error.phone}</p>
         )}
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.emergencyContactAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             emergencyContact: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}