import React from 'react';
import noResultsFound from "../Icons/NoresultsFound.svg"

const ReimbPaySlip = ()=> {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center">
      <img
          src={noResultsFound}
          alt="No Results Found"
          className="mx-auto mb-4 w-48 h-48"
        />
        <h1 className="text-2xl font-bold text-gray-800 mb-2">No Results Found</h1>
       
       
      </div>
    </div>
  );
}

export default ReimbPaySlip;
