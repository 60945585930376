import React from "react";
import TextField from "../../customComponents/customTextField";
import { Checkbox } from "@material-ui/core";
import Select from "../../customComponents/customSelect";
import CustomRadioSelect from "../../customComponents/customRadioSelection";

const GeneralInformation = ({ formData, setFormData }) => {
  const options = ["Yes", "No"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionSelect = (e) => {
    if(e.target.value == 'Yes'){
      setFormData({...formData,[e.target.name]:true})
    }else if(e.target.value == 'No'){
      setFormData({...formData,[e.target.name]:false})
    }
 }

  return (
    <div>
      <h3 className="text-xl my-2 p-2 font-semibold">
        Step 1: General Information
      </h3>
      <div className="flex">
        <div className="flex flex-col justify-center items-end ">
          <TextField
            name="templateName"
            type="text"
            isMandatory={true}
            label="Title"
            value={formData.templateName}
            onChange={handleChange}
          />
          <div className="mb-4 flex items-center">
            <label className="block text-sm font-medium text-gray-700 mr-5">
              Enabled
            </label>
            <div className="flex flex-col">
              <Checkbox
                name="isEnabled"
                value={formData.isEnabled}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-4 flex items-center">
            <label className="block mb-4 text-sm font-medium text-gray-700 mr-5">
              Can employee request for letter
            </label>
            <CustomRadioSelect
              name="canBeRequestedByEmployee"
              options={options}
              isMandatory={false}
              value={formData.canBeRequestedByEmployee}
              onSelect={handleOptionSelect}
              />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
