import api from "../services/index";

const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createProfile: builder.query({
      query: (params) => ({
        url: `/api/employee/getEmployeeData`,
        method: "get",
        params, // Include the query parameters here
      }),
    }),
  }),
});

export default profileApi;
export const { useCreateProfileQuery, useLazyCreateProfileQuery } = profileApi;
