import api from "../services/index";

const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
     createLogin: builder.mutation({
      query: (payload) => ({
        url: "/api/auth/login",
        method: "POST",
        body:  payload ,
      }),
    }),
    payslip: builder.query({
      query: (empId) => ({
        url: `/api/payslipmanagement/fetchlatestpaysliprecord?employeeId=${empId}`,
        method: "GET",
      }),
    }),
    payslipDetails: builder.mutation({
      query: (payload) => ({
        url: "/api/payslipmanagement/fetchemployeepaysliprecordbydate",
        method: "POST",
        body:payload
      }),
    }),
    fetchAttendance:builder.mutation({
      query:(payload) =>({
        url: "/api/employee/audit/fetchattendanceinfo",
        method:"POST",
        body:payload
      }),
    }),
    employeeAudit: builder.query({
      query: (date) => ({
        url: `/api/employee/audit/attendence?userSelectedDate=${date}` ,
        method: "GET",
      }),
    }),
    signinstatus: builder.query({
      query: () => ({
        url: `/api/employee/audit/signinstatus` ,
        method: "GET",
      }),
    }),
  }),
});

export default loginApi;
export const {  useCreateLoginMutation,useLazyPayslipQuery,usePayslipDetailsMutation,useFetchAttendanceMutation,useLazyEmployeeAuditQuery,useSigninstatusQuery } = loginApi;
